import { Component, Input, ViewEncapsulation } from '@angular/core';
import { AccordionItem, LayoutResponseEntry, ResponseEntry, isEmissionsAccordion, isEmissionsChildData } from '@digital-brochure/services';
import { TranslateService } from '@ngx-translate/core';
import { LogService } from '@rrvis/util';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrl: './accordion.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: { class: 'accordion container' },
})
export class AccordionComponent {
  @Input() items: AccordionItem[] = [];

  @Input() meta: any;

  constructor(
    private logger: LogService,
    private translate: TranslateService,
  ) {}

  getItemDescription(item: AccordionItem) {
    if (isEmissionsAccordion(item) && isEmissionsChildData(item.child)) {
      return [
        item.description.wltp ? this.translateOverview(item.description.wltp) : "",
        item.description.nedc ? this.translateOverview(item.description.nedc) : "",
        item.description.noise ? this.translateOverview(item.description.noise) : ""
      ];
    }
    if (!isEmissionsAccordion(item)) {
      return [item.description];
    }
    return [];
  }

  private translateOverview(overview: LayoutResponseEntry[]): string {
    try {
      return overview
        .map((o) => {
          if (!o) {
            return '';
          }
          return this.translate.instant(o.translationKey, this.translateUnitsInEntryData(o?.data));
        })
        .join(' ');
    } catch (err: unknown) {
      this.logger.error('Failed to map emissions overview', { err });
      return '';
    }
  }

  private translateUnitsInEntryData(data: ResponseEntry): ResponseEntry {
    return {
      ...data,
      units: {
        metric: data?.units?.metric && this.translate.instant(data?.units?.metric),
        imperial: data?.units?.imperial && this.translate.instant(data?.units?.imperial),
      },
    };
  }
}
