import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { VehicleData } from '@digital-brochure/services';
import { Theme } from '@rrvis/ui';
import { selectActiveTheme, selectBrand, selectIsOverlayActive } from '../../state/selectors';
import { ConfigurationDataService } from '../../services/configuration-data.service';
import { Language } from '../../services/language-manager.service';
import { ErrorVariant } from '../error/error.component';
import { GlobalActions } from '../../state/actions/global';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrl: './splash.component.scss',
})
export class SplashComponent implements OnInit {
  public brand$ = this.store.select(selectBrand);

  public theme$ = this.store.select(selectActiveTheme);

  public overlayActive$ = this.store.select(selectIsOverlayActive);

  configId: string | null = null;

  lang: string | null = null;

  loading: boolean = false;

  data?: VehicleData | null;

  error?: ErrorVariant | null;

  constructor(
    private route: ActivatedRoute,
    private configurationData: ConfigurationDataService,
    private store: Store,
  ) {}

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.subscribe(async (params: ParamMap) => {
      this.configId = params.get('id');
      this.lang = params.get('lang');
      this.store.dispatch(GlobalActions.setConfigId({ configId: this.configId }));
      try {
        this.loading = true;
        const { data, error } = await this.configurationData.getData(this.lang || Language.EN, this.configId!);
        if (!error) {
          const theme = data.meta.blackBadge ? Theme.DARK : Theme.LIGHT;
          this.store.dispatch(
            GlobalActions.setTheme({
              theme,
              ctaButtonColor: data.meta.ctaButtonColor,
              activeSelectionBorderColor: data.meta.activeSelectionBorderColor,
            }),
          );
          this.data = data;
        }
        this.error = error;
      } finally {
        this.loading = false;
      }
    });
  }
}
