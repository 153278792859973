import { DateTime } from 'luxon';
import axios from 'axios';
import { EveResponse, EveData, EveMedia, isEveError, isValidEveResponse } from './types';
import { fixBrochureImportCodes } from '@rrvis/code-mapper';

export const mapMediaFiles = (mediaFiles: string[]): EveMedia => {
  const output: EveMedia = {};
  const regex = /([^_]+_[^_]+_[^_]+_*[\d]*)\.(mp4|jpg)$/;

  mediaFiles.forEach((file) => {
    const match = file.match(regex);
    if (match) {
      output[match[1]] = `${process.env.EVE_MEDIA_ENDPOINT}/${file}`;
    }
  });

  return output;
};

export const fetchEveAPI = async (code: string): Promise<{ status: number; data: EveResponse }> =>
  axios
    .get<EveResponse>(`${process.env.EVE_API_ENDPOINT}/${code}`, {
      headers: { clientID: 'RRBrochureFrontEnd' },
    })
    .then(async (res) => ({ status: res.status, data: res.data }));

const isAvailable = (data: EveResponse): boolean => {
  if (!data.configuration.creationDate) {
    // Old config or from SMS/RROC
    return data.resources.images.length >= 25 && data.resources.videos.length >= 2;
  }
  const creationTime = DateTime.fromISO(data.configuration.creationDate);
  const effectDate = DateTime.fromISO(data.configuration.effectDate);

  // RR25 config
  if (
    creationTime < DateTime.fromISO('2022-10-17') &&
    data.configuration.modelRange === 'RR25' &&
    data.resources.images.length <= 10
  ) {
    return data.resources.images.length > 3 && data.resources.videos.length >= 1;
  }

  if (creationTime > DateTime.fromISO('2022-10-13') && data.configuration.modelRange === 'RR25') {
    return data.resources.images.length > 10 && data.resources.videos.length >= 1;
  }

  // Newer config from visualiser FE version >= 410

  if (
    creationTime < DateTime.fromISO('2022-05-12') &&
    data.configuration.modelRange === 'RR12' &&
    effectDate > DateTime.fromISO('2022-07-31')
  ) {
    return data.resources.images.length >= 26 && data.resources.videos.length >= 1;
  }
  if (
    creationTime < DateTime.fromISO('2022-05-12') &&
    data.configuration.modelRange === 'RR11' &&
    effectDate > DateTime.fromISO('2022-07-31')
  ) {
    return data.resources.images.length >= 26 && data.resources.videos.length >= 1;
  }
  if (effectDate > DateTime.fromISO('2022-03-31')) {
    return data.resources.images.length >= 25 && data.resources.videos.length >= 1;
  }

  // Old config or from SMS/RROC
  return data.resources.images.length >= 25 && data.resources.videos.length >= 2;
};

export const getEveData = async (code: string): Promise<EveData> => {
  const { status, data } = await fetchEveAPI(code);
  if (isEveError(data)) {
    throw new Error(data.Code);
  }

  if (!isValidEveResponse(data)) {
    throw new Error('UnknownError');
  }

  if (status === 202 || !isAvailable(data)) {
    throw new Error('NotYetAvailable');
  }

  const validityDate = DateTime.fromISO(data.validity);
  const isExpired = DateTime.now() > validityDate;

  if (isExpired) {
    throw new Error('ExpiredError');
  }

  const images = mapMediaFiles(data.resources.images);
  const videos = mapMediaFiles(data.resources.videos);

  return {
    isBlackBadge: data.configuration.isBlackBadge,
    agModelCode: data.configuration.agModelCode,
    modelRange: data.configuration.isBlackBadge ? `${data.configuration.modelRange}BB` : data.configuration.modelRange,
    featureCodes: fixBrochureImportCodes(data.configuration?.featureCodes ?? []),
    images,
    videos,
    effectDate: data.configuration.effectDate,
    market: data.configuration.market,
  };
};
