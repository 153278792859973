import type { Language } from '../services/language-manager.service';

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
  VIOLET = 'violet',
}

export interface AppState {
  brand: string;
  configId: string;
  defaultLang: Language;
  lang: string;
  theme: Theme;
  overlayActive: boolean;
  ctaButtonColor: string;
  activeSelectionBorderColor: string;
}
