import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Language } from '../services/language-manager.service';
import { AppState } from './state.types';

export const selectApp = createFeatureSelector<AppState>('app');

export const selectBrand = createSelector(selectApp, (state: AppState) => state.brand);
export const selectUserDefaultLang = createSelector(selectApp, (state: AppState) => state.defaultLang || Language.EN);
export const selectActiveTheme = createSelector(selectApp, (state: AppState) => state.theme);
export const selectIsOverlayActive = createSelector(selectApp, (state: AppState) => state.overlayActive);
export const selectConfigId = createSelector(selectApp, (state: AppState) => state.configId);
export const selectConfigIdRef = createSelector(selectApp, (state: AppState) => `/${state.lang}/${state.configId}`);
export const selectThemeColours = createSelector(selectApp, (state: AppState) => ({
  ctaButtonColor: state.ctaButtonColor,
  activeSelectionBorderColor: state.activeSelectionBorderColor,
}));
