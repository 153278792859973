/* eslint-disable function-paren-newline */
import merge from 'lodash.merge';
import cloneDeep from 'lodash.clonedeep';
import { get } from 'lodash';

import {
  ContentFile,
  ContentElement,
  EveMedia,
  RawContentElement,
  Language,
  Specifications,
  ContentElementType,
  SpecificationsElement,
  AccordionElement,
  StructureElement,
  isConditionalStructureElement,
  AccordionElementItem,
  EmissionsElement,
  FootnotesElement,
  isEmissionsAccordion,
  Languages,
  CTAItem,
  CTAElement,
  CTAGroupElement,
  CTAConElement,
} from './types';
import { logger } from './logger';
import { mapEmissionsDisclaimer } from './emissions';
import { readContentFilesFromS3 } from './helper/s3';
import { BrochureConfigFile, getBrochureFilePath } from './helper/file-paths';
import { DigitalBrochureEmissionsData } from './interfaces/emissions';
import { CountryCode, createConLink } from './configurator';
import { Variant } from './helper/model.interface';

const loadFile = async (
  language: Language,
  file: BrochureConfigFile,
  derivative: string,
  modelRange: string,
  modelCode = 'main',
  ignoreMissing = false
) => {
  const filePath = getBrochureFilePath(language, file, derivative, modelRange, modelCode);
  const content = await readContentFilesFromS3<ContentFile>(filePath, ignoreMissing);
  if (!content && language !== Languages.EN && modelCode !== 'main') {
    return loadFile(language, file, derivative, modelRange);
  }
  if (!content && language !== Languages.EN) {
    return loadFile(Languages.EN, file, derivative, modelRange);
  }
  if (!content && language === Languages.EN) {
    logger.error(`${file} file does not exist.`, {
      filePath,
    });
  }
  return content;
};

/**
 * Fetches the latest structure and bricks config from the RRVIS Assets.
 * In case there are no files it will try to load the deprecated combined files.
 *
 * @param language
 * @param derivative
 * @param modelRange
 * @param modelCode
 * @returns
 */
const getConfigFromS3 = async (
  language: Language,
  derivative: string,
  modelRange: string,
  modelCode = 'main'
): Promise<ContentFile | null> => {
  const ignoreMissingFile = modelCode !== 'main';
  const structure = await loadFile(
    language,
    BrochureConfigFile.STRUCTURE,
    derivative,
    modelRange,
    modelCode,
    ignoreMissingFile
  );
  const bricks = await loadFile(
    language,
    BrochureConfigFile.BRICKS,
    derivative,
    modelRange,
    modelCode,
    ignoreMissingFile
  );

  if (!structure || !bricks) {
    logger.warn('Structure and/or Brick file does not exist. Trying deprecated files now.', {
      structure,
      bricks,
      language,
      derivative,
      modelRange,
      modelCode,
      ignoreMissingFile,
    });

    const mainFile = await loadFile(
      language,
      BrochureConfigFile.FALLBACK,
      derivative,
      modelRange,
      modelCode,
      ignoreMissingFile
    );
    return mainFile;
  }
  return {
    structure,
    bricks,
  };
};

export const readContentFiles = async (
  language: Language,
  derivative: string,
  modelRange: string,
  agModelCode: string
): Promise<[ContentFile, ContentFile | null]> => {
  const mainFile = await getConfigFromS3(language, derivative, modelRange);

  if (!mainFile) {
    throw new Error(`DerivativeMainFileMissing`);
  }
  const specificFile = await getConfigFromS3(language, derivative, modelRange, agModelCode);

  return [mainFile, specificFile];
};

const mapComponentMedia = (component: RawContentElement, images: EveMedia, videos: EveMedia): ContentElement => {
  const clone = cloneDeep(component) as unknown as ContentElement;

  if (component.data.cameraPosition) {
    clone.data.image = images[component.data.cameraPosition] || '';
    delete (clone as unknown as RawContentElement).data.cameraPosition;
  }

  if (component.data.videoPosition) {
    clone.data.video = videos[component.data.videoPosition] || '';
    delete (clone as unknown as RawContentElement).data.videoPosition;
  }

  if (component.data.shots) {
    clone.data.shots = component.data.shots.map((shot) => ({
      image: images[shot.cameraPosition] || '',
      caption: shot.caption,
    }));
  }

  return clone;
};

const mapItem = (item: AccordionElementItem, emissions: DigitalBrochureEmissionsData) => {
  switch (item.child?.type) {
    case ContentElementType.Emissions: {
      return {
        ...item,
        description: emissions.overview,
      };
    }
    default:
      return item;
  }
};

const mapCTAItem = (item: CTAItem, configuratorUrl: string): CTAElement => {
  switch (item.type) {
    case ContentElementType.CTA_CON: {
      return {
        type: ContentElementType.CTA,
        data: {
          label: item.data.label,
          url: configuratorUrl,
          theme: item.data.theme || 'violet',
        },
      };
    }
    default:
      return {
        type: ContentElementType.CTA,
        data: {
          label: item.data.label,
          url: item.data.url,
          theme: item.data.theme || 'violet',
        },
      };
  }
};

const mapFootnotes = (element: FootnotesElement, emissions: DigitalBrochureEmissionsData) => {
  return get(element, 'data.paragraphs', []).flatMap((text) => {
    switch (text) {
      case 'EMISSION_DISCLAIMER': {
        if (!element.data.additionalData) {
          return [text];
        }
        return mapEmissionsDisclaimer(element.data.additionalData, emissions.source, emissions.validFrom) ?? [text];
      }

      default:
        return [text];
    }
  });
};

export const mapComponent = (
  component: RawContentElement,
  images: EveMedia,
  videos: EveMedia,
  specs: Specifications,
  emissions: DigitalBrochureEmissionsData,
  configuratorUrl: string
): ContentElement => {
  switch (component.type) {
    case ContentElementType.Specifications:
      return {
        ...component,
        data: {
          specs,
        },
      } as SpecificationsElement;
    case ContentElementType.Emissions:
      return {
        ...component,
        data: {
          image: (component as EmissionsElement).data.image,
          tables: emissions.tables,
          source: emissions.source,
        },
      } as EmissionsElement;
    case ContentElementType.Footnotes:
      return {
        ...component,
        data: {
          paragraphs: mapFootnotes(component as FootnotesElement, emissions),
        },
      } as FootnotesElement;
    case ContentElementType.CTA:
      return {
        type: ContentElementType.CTA,
        data: {
          label: (component as CTAElement).data.label,
          url: (component as CTAElement).data.url,
          theme: (component as CTAElement).data.theme || 'violet',
        },
      };
    case ContentElementType.CTA_CON:
      return {
        type: ContentElementType.CTA,
        data: {
          label: (component as CTAConElement).data.label,
          url: configuratorUrl,
          theme: (component as CTAConElement).data.theme || 'violet',
        },
      };
    case ContentElementType.CTA_GROUP:
      return {
        ...component,
        data: {
          ...component.data,
          items: (component as CTAGroupElement).data.items.map((item) => ({ ...mapCTAItem(item, configuratorUrl) })),
        },
      } as CTAGroupElement;
    case ContentElementType.Accordion:
      return {
        ...component,
        data: {
          ...component.data,
          items: (component as AccordionElement).data.items.map(
            (item) =>
              !isEmissionsAccordion(item) && {
                ...mapItem(item, emissions),
                child: mapComponent(
                  item.child as unknown as RawContentElement,
                  images,
                  videos,
                  specs,
                  emissions,
                  configuratorUrl
                ),
              }
          ),
        },
      } as AccordionElement;
    default:
      return mapComponentMedia(component, images, videos);
  }
};

const removeContentElementsWithMissingMedia = (contentElements: ContentElement[]): ContentElement[] => {
  return contentElements
    .map((contentElement) => {
      switch (contentElement.type) {
        case ContentElementType.Carousel:
          return {
            ...contentElement,
            data: {
              ...contentElement.data,
              shots: contentElement.data.shots.filter((shot) => shot.image),
            },
          };
        default:
          return contentElement;
      }
    })
    .filter((contentElement) => {
      switch (contentElement.type) {
        case ContentElementType.HeroImage:
          return contentElement.data.image;
        case ContentElementType.TextImage:
          return contentElement.data.image;
        case ContentElementType.Teaser:
          return contentElement.data.image;
        case ContentElementType.Carousel:
          return contentElement.data.shots.length > 0 && contentElement.data.shots.every((shot) => shot.image);
        case ContentElementType.Emissions:
          return contentElement.data.image;
        default:
          return true;
      }
    });
};

export const filterContentElements = (contentElements: ContentElement[]): ContentElement[] => {
  return removeContentElementsWithMissingMedia(contentElements);
};

export const checkContentStructureCondition = (condition: string[][], featureCodes: string[]): boolean => {
  if (condition.length > 0) {
    // Outer array = AND
    return condition.every((partial) =>
      // Inner array = OR
      partial.some((pattern) => {
        const isNegation = pattern.trim().startsWith('!');
        const plainPattern = (isNegation ? pattern.substr(1) : pattern).trim();
        // Wildcard
        if (pattern.includes('*')) {
          const regexp = new RegExp(`^${plainPattern.replace(/\*/g, '.*')}$`);
          return isNegation
            ? !featureCodes.some((featureCode) => regexp.test(featureCode))
            : featureCodes.some((featureCode) => regexp.test(featureCode));
        }
        // Regular
        return isNegation ? !featureCodes.includes(plainPattern) : featureCodes.includes(plainPattern);
      })
    );
  }
  return true;
};

export const filterContentStructure = (structure: StructureElement[], featureCodes: string[]): string[] => {
  return structure
    .map((item): string | string[] => {
      if (typeof item === 'string') {
        return item;
      }
      if (isConditionalStructureElement(item)) {
        // Find first item with matching condition
        const match = item.find((option) => checkContentStructureCondition(option.condition, featureCodes));
        if (match) {
          return match.bricks;
        }
      }
      return '';
    })
    .flat()
    .filter(Boolean);
};

export const getContent = async (
  language: Language,
  userCountry: CountryCode,
  isBlackBadge: boolean,
  modelVariant: Variant,
  variantId: string,
  agModelCode: string,
  derivativeId: string,
  images: {
    [key: string]: string;
  },
  videos: {
    [key: string]: string;
  },
  specs: Specifications,
  featureCodes: string[],
  emissions: DigitalBrochureEmissionsData
): Promise<ContentElement[]> => {
  try {
    const [main, specific] = await readContentFiles(language, derivativeId, variantId, agModelCode);
    let merged: ContentFile = main;

    const configuratorUrl = createConLink({
      isBlackBadge,
      activeVariant: modelVariant as unknown as Variant,
      language,
      userCountry: userCountry,
      featureCodes,
    });
    if (specific) {
      merged = {
        bricks: merge(main.bricks, specific.bricks),
        structure: specific.structure ? specific.structure : main.structure,
      };
    }
    return filterContentElements(
      filterContentStructure(merged.structure, featureCodes)
        .map((id) => merged.bricks[id])
        .filter(Boolean)
        .map((component) => mapComponent(component, images, videos, specs, emissions, configuratorUrl))
    );
  } catch (e: any) {
    logger.error(e.message, { language, modelVariant, variantId, agModelCode });
    throw new Error('MissingContentError');
  }
};
