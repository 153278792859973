export enum METRICS {
  BROCHURE_INVOCATION = 'brochure invoked',
  BROCHURE_ERROR = 'brochure error',
  BROCHURE_ERROR_NOT_YET_AVAILABLE = 'brochure not yet available',
  BROCHURE_ERROR_EXPIRED = 'brochure expired',
  BROCHURE_MISSING_VARIANT_CONFIG = 'missing variant config',
  BROCHURE_UNKNOWN_ERROR = 'unknown brochure error',
}

export const METRIC_NAMESPACE = 'RR-Digital-Brochure';
