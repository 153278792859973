import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { FooterLink, FooterSocialLink } from '@rrvis/ui';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class FooterComponent {
  @Input() links: FooterLink[];

  @Input() socialNetworks: FooterSocialLink[];

  @Input() language: string;

  @Input() weChatQrCode: string;

  @Input() logoHref: string;

  @Output() languageClicked = new EventEmitter();

  handleLanguageClicked() {
    this.languageClicked.emit();
  }
}
