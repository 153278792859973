import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs';
import { LanguageManagerService } from '../../services/language-manager.service';
import { TranslationActions } from '../actions/translation';
import { selectUserDefaultLang } from '../selectors';

@Injectable()
export class LanguageEffect {
  private alreadyAddedVdmlLang = [];

  setLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(TranslationActions.setLang),
        concatLatestFrom(() => this.store.select(selectUserDefaultLang)),
        tap(([{ lang }, defaultLang]) => {
          if (this.languageService.SUPPORTED_LANGS.some((supportedLang) => supportedLang === lang)) {
            this.languageService.setLanguage(lang);
          } else {
            this.languageService.setLanguage(defaultLang);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private languageService: LanguageManagerService,
    private translate: TranslateService,
    private store: Store,
  ) {}
}
