import { Component, Input, OnInit, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { selectBrand } from '../../state/selectors';

export enum ErrorVariant {
  GENERIC = 'GENERIC',
  NOT_YET_AVAILABLE = 'NOT_YET_AVAILABLE',
  NOT_FOUND = 'NOT_FOUND',
  EXPIRED = 'EXPIRED',
}

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrl: './error.component.scss',
})
export class ErrorComponent implements OnInit {
  @Input() variant?: ErrorVariant = ErrorVariant.GENERIC;

  public title: string = '';

  public headline: string = '';

  public description: string = '';

  private readonly store = inject(Store);

  public brand$ = this.store.select(selectBrand);

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    switch (this.variant) {
      case ErrorVariant.NOT_YET_AVAILABLE:
        this.title = 'error.title';
        this.headline = 'error.notYetAvailable.headline';
        this.description = 'error.notYetAvailable.text';
        break;
      case ErrorVariant.NOT_FOUND:
        this.title = 'error.title';
        this.headline = 'error.notFound.headline';
        this.description = 'error.notFound.text';
        break;
      case ErrorVariant.EXPIRED:
        this.title = 'error.title';
        this.headline = 'error.expired.headline';
        this.description = 'error.expired.text';
        break;
      default:
        this.title = 'error.title';
        this.headline = 'error.generic.headline';
        this.description = 'error.generic.text';
        break;
    }
  }
}
