import {
  isGenericInteriorCode,
  removeHelperCodesFilter,
  removeLegacySpecialOrderingCodes,
  removeSpecialOrderingSystemCodes,
  removeStateCodes,
  resolveConfigInheritanceCodes,
  resolveCustomColourCodes,
  resolveCustomOrderColourCodes,
  resolveCustomStateCode,
  resolveGenericColourCodesForCosy,
  resolveIndividualOrderColourCodes,
  resolveSpecialOrderColourCodes,
  splitGenericInteriorCode,
} from '@rrvis/code-mapper';
import { ExternalVehicleDataBundleWithGroupId } from './configuration';

export const resolveGenericColourCodes = (code, genericColoursMap): string => {
  return genericColoursMap && genericColoursMap[code] ? `${code}_${genericColoursMap[code]}` : code;
};

const applyGenericColourCode = (configuration: string[], genericColoursMap: Record<string, string>) =>
  configuration.map((code) => resolveGenericColourCodes(code, genericColoursMap));

const resolveCode = (configuration: string[], genericColoursMap: Record<string, string>, _forCosy = false): string[] => {
  const configCodes = applyGenericColourCode(configuration, genericColoursMap);

  return configCodes
    .map((code) => resolveConfigInheritanceCodes(code, configCodes))
    .filter(Boolean)
    .map((code) => resolveSpecialOrderColourCodes(code))
    .flatMap((code) => resolveCustomOrderColourCodes(code))
    .flatMap((code) => resolveIndividualOrderColourCodes(code))
    .filter(removeHelperCodesFilter)
    .filter(removeSpecialOrderingSystemCodes);
};

const resolveSelectedFeatureCodes = (configuration: string[], genericColoursMap = {}): string[] =>
  resolveCode(configuration, genericColoursMap)
    .map((code) => resolveCustomStateCode(code))
    .filter(removeStateCodes);

export const resolveSelectedFeatureCodesForCosy = (configuration: string[], genericColoursMap = {}): string[] => {
  return resolveCode(configuration, genericColoursMap, true)
    .map(resolveGenericColourCodesForCosy)
    .map((configCode) => resolveCustomColourCodes({ configCode, onlyFeature: true }))
    .filter(removeLegacySpecialOrderingCodes);
};

export const resolveSelectedFeatureCodesForEve = (configuration: string[], genericColoursMap = {}): string[] => {
  return resolveSelectedFeatureCodes(configuration, genericColoursMap).filter(removeLegacySpecialOrderingCodes);
};

export const resolveSelectedFeatureCodesForBrochure = (configuration: string[], genericColoursMap = {}): string[] => {
  return resolveSelectedFeatureCodes(configuration, genericColoursMap).map((code) => {
    if (!isGenericInteriorCode(code)) {
      return code;
    }
    const { feature, material, color } = splitGenericInteriorCode(code, true);
    return `${feature}_${material}_${color}`;
  });
};

export const resolveSelectedFeatureCodesForGarage = (configuration: string[], genericColoursMap = {}): string[] => {
  const configs = applyGenericColourCode(configuration, genericColoursMap);
  return configs
    .map((code) => resolveConfigInheritanceCodes(code, configs))
    .filter(Boolean)
    .filter(removeHelperCodesFilter)
    .filter(removeLegacySpecialOrderingCodes)
    .filter(removeStateCodes);
};

export const groupBundlesByGroupId = (bundles: ExternalVehicleDataBundleWithGroupId[]) =>
  bundles.reduce(
    (acc, curr) => {
      if (!curr.groupId) {
        return acc;
      }
      if (!acc[curr.groupId]) {
        acc[curr.groupId] = [];
      }
      if (!acc[curr.groupId].find((item) => item.bundleId === curr.bundleId)) {
        acc[curr.groupId].push(curr);
      }
      return acc;
    },
    {} as Record<string, ExternalVehicleDataBundleWithGroupId[]>
  );

export const compareSpecs = (a1, a2) => a1.reduce((a, c) => a + a2.includes(c), 0);

export const checkMatches = (
  configurationCodes: string[],
  bundles: ExternalVehicleDataBundleWithGroupId[],
  index = 0,
  highestMatch = -1,
  highestMatchBundle = null
): ExternalVehicleDataBundleWithGroupId => {
  if (bundles.length === 0) {
    return null;
  }
  if (index > bundles.length - 1) {
    return highestMatchBundle ?? bundles[index];
  }
  const totalOverlap = compareSpecs(bundles[index].featureCodes, configurationCodes);
  if (totalOverlap > highestMatch) {
    return checkMatches(configurationCodes, bundles, index + 1, totalOverlap, bundles[index]);
  }
  return checkMatches(configurationCodes, bundles, index + 1, highestMatch, highestMatchBundle);
};

export const findBestMatchingBundlePerGroup = (
  configurationCodes: string[],
  bundles: Record<string, ExternalVehicleDataBundleWithGroupId[]>
) =>
  Object.entries(bundles).flatMap(([groupId, values]) => {
    if (groupId === 'unknown') {
      return values;
    }
    if (values.length === 1) {
      return values[0];
    }
    const result = checkMatches(configurationCodes, values);
    return result;
  });
