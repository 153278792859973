import {
  AfterViewInit,
  Component,
  ComponentRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CTAElement, ContentElement, ContentElementType } from '@digital-brochure/services';
import { ButtonDefaultComponent, EmissionsComponent, SpecificationTableComponent, Specifications, Theme } from '@rrvis/ui';
import { DebugElementComponent } from '../debug-element/debug-element.component';
import { TextBlockComponent } from '../text-block/text-block.component';
import { HeroImageComponent } from '../hero-image/hero-image.component';
import { HeroVideoComponent } from '../hero-video/hero-video.component';
import { TextImageComponent } from '../text-image/text-image.component';
import { CarouselComponent } from '../carousel/carousel.component';
import { TeaserComponent } from '../teaser/teaser.component';
import { HeadlineBlockComponent } from '../headline-block/headline-block.component';
import { FootNotesComponent } from '../foot-notes/foot-notes.component';
import { mapEmissonsData } from '../../utils/emissions';
import { AccordionComponent } from '../accordion/accordion.component';
import { ASSETS_BASE_PATH } from '../../injection-tokens';
import { CTAGroupComponent } from '../cta-group/cta-group.component';

type ComponentReference = {
  componentId: string;
  ref: ComponentRef<ContentElementType>;
};

const componentMapping: { [key in ContentElementType]: any } = {
  [ContentElementType.TextBlock]: TextBlockComponent,
  [ContentElementType.HeroImage]: HeroImageComponent,
  [ContentElementType.HeroVideo]: HeroVideoComponent,
  [ContentElementType.TextImage]: TextImageComponent,
  [ContentElementType.Carousel]: CarouselComponent,
  [ContentElementType.Teaser]: TeaserComponent,
  [ContentElementType.Footnotes]: FootNotesComponent,
  [ContentElementType.CTA]: ButtonDefaultComponent,
  [ContentElementType.Emissions]: EmissionsComponent,
  [ContentElementType.Specifications]: SpecificationTableComponent,
  [ContentElementType.HeadlineBlock]: HeadlineBlockComponent,
  [ContentElementType.Accordion]: AccordionComponent,
  [ContentElementType.CTA_GROUP]: CTAGroupComponent,
  [ContentElementType.CTA_CON]: ButtonDefaultComponent,
};

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrl: './content.component.scss',
  host: { class: 'content' },
  encapsulation: ViewEncapsulation.None,
})
export class ContentComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input() content: ContentElement[] = [];

  @Input() meta: any;

  @ViewChild('container', { read: ViewContainerRef })
  container!: ViewContainerRef;

  references: ComponentReference[] = [];

  constructor(
    @Inject(ASSETS_BASE_PATH) private assetsBasePath: string,
    private translate: TranslateService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes?.content?.isFirstChange() && changes.content) {
      this.renderComponents(changes.content.currentValue);
    }
  }

  ngAfterViewInit(): void {
    if (this.content && this.content.length > 0) {
      this.renderComponents(this.content);
    }
  }

  ngOnDestroy(): void {
    this.references.forEach((componentRef) => {
      componentRef.ref.destroy();
    });
    this.references = [];
  }

  private createComponent(type: ContentElementType): ComponentRef<ContentElementType> {
    const mappedComponent = componentMapping[type] || DebugElementComponent;
    const ref = this.container.createComponent<ContentElementType>(mappedComponent);
    this.references.push({ componentId: type, ref });
    return ref;
  }

  private renderComponents(content: ContentElement[]) {
    if (!this.container) {
      return;
    }
    this.container.clear();

    content.forEach((element) => {
      const { type } = element;
      const mappedComponent = this.createComponent(type);
      switch (type) {
        case ContentElementType.TextBlock:
          const textBlock = mappedComponent.instance as unknown as TextBlockComponent;
          textBlock.title = element.data.title;
          textBlock.description = element.data.description;
          break;
        case ContentElementType.HeroImage:
          const heroImage = mappedComponent.instance as unknown as HeroImageComponent;
          heroImage.title = element.data.title;
          heroImage.description = element.data.description;
          heroImage.image = element.data.image;
          break;
        case ContentElementType.HeroVideo:
          const heroVideo = mappedComponent.instance as unknown as HeroVideoComponent;
          heroVideo.title = element.data.title;
          heroVideo.subline = element.data.subline;
          heroVideo.label = element.data.label;
          heroVideo.startVideoLabel = this.translate.instant('stage.startVideoLabel');
          heroVideo.videoUrl = element.data.video;
          heroVideo.posterUrl = element.data.image;
          heroVideo.wordmark = element.data.wordmark;
          heroVideo.videoFallback = this.translate.instant('stage.videoFallback');
          break;
        case ContentElementType.TextImage:
          const textImage = mappedComponent.instance as unknown as TextImageComponent;
          textImage.title = element.data.title;
          textImage.description = element.data.description;
          textImage.image = element.data.image.startsWith('/') ? `/public${element.data.image}` : element.data.image;
          textImage.reversed = element.data.reversed;
          break;
        case ContentElementType.Carousel:
          const carousel = mappedComponent.instance as unknown as CarouselComponent;
          carousel.shots = element.data.shots;
          break;
        case ContentElementType.Teaser:
          const teaser = mappedComponent.instance as unknown as TeaserComponent;
          teaser.title = element.data.title;
          teaser.paragraphs = element.data.paragraphs;
          teaser.image = element.data.image;
          teaser.link = element.data.link;
          teaser.theme = this.meta.blackBadge ? 'dark' : 'light';
          break;
        case ContentElementType.Footnotes:
          const footNotes = mappedComponent.instance as unknown as FootNotesComponent;
          footNotes.paragraphs = element.data.paragraphs;
          break;
        case ContentElementType.CTA:
          const cta = mappedComponent.instance as unknown as ButtonDefaultComponent;
          cta.label = element.data.label;
          cta.externalUrl = element.data.url;
          cta.theme = Theme.VIOLET;
          cta.target = '_self';
          break;
        case ContentElementType.CTA_GROUP:
          const ctaGroup = mappedComponent.instance as unknown as CTAGroupComponent;
          ctaGroup.iconTheme = this.meta.blackBadge ? Theme.DARK : Theme.LIGHT;
          ctaGroup.items = element.data.items as unknown as CTAElement[];
          break;
        case ContentElementType.Emissions:
          const emissions = mappedComponent.instance as unknown as EmissionsComponent;
          if (element.data.tables) {
            emissions.emissions = mapEmissonsData(this.translate, element.data.tables);
          }
          emissions.image = element.data.image.startsWith('/') ? `/public${element.data.image}` : element.data.image;
          break;
        case ContentElementType.Accordion:
          const accordion = mappedComponent.instance as unknown as AccordionComponent;
          accordion.meta = this.meta;
          accordion.items = element.data.items;
          break;
        case ContentElementType.Specifications:
          const specificationTable = mappedComponent.instance as unknown as SpecificationTableComponent;
          this.translateModelName(element.data.specs);
          specificationTable.specs = element.data.specs;
          specificationTable.codeText = this.translate.instant('SUMMARY_CODE_TEXT');
          specificationTable.codeColour = this.translate.instant('SUMMARY_COLOR_LABEL');
          specificationTable.codeLabel = this.translate.instant('SUMMARY_CODE_LABEL');
          specificationTable.genericColours = this.meta.genericColours;
          specificationTable.splitterImages = this.meta.splitterImages;
          break;
        case ContentElementType.HeadlineBlock:
          const headlineBlock = mappedComponent.instance as unknown as HeadlineBlockComponent;
          headlineBlock.title = element.data.title;
          break;
        default:
          const debugElement = mappedComponent.instance as unknown as DebugElementComponent;
          debugElement.element = element;
          break;
      }
      mappedComponent.changeDetectorRef.detectChanges();
    });
  }

  private translateModelName(specs: Specifications) {
    // @ts-expect-error hack to translate car name
    specs[0].content[0].content[0].name = this.translate.instant(specs[0].content[0].content[0].name);
  }
}
