import {} from '../content';
import { readContentFilesFromS3 } from './s3';
import { ModelsConfigFile } from '../interfaces/models-config';
import { getModelsConfigFilePath } from './file-paths';
import { logger } from '../logger';

export const modelRangeToDerivativeIdFallback = (modelRange: string) => {
  const mapping: Record<string, string> = {
    RR5: 'Wraith',
    RR6: 'Dawn',
    RR11: 'Phantom',
    RR12: 'Phantom',
    RR21: 'Ghost',
    RR22: 'Ghost',
    RR25: 'Spectre',
    RR31: 'Cullinan',
  };

  return mapping[modelRange];
};

export const getModelsConfig = async () => readContentFilesFromS3<ModelsConfigFile>(getModelsConfigFilePath());

export const modelRangeToModelVariant = async (modelRange: string, effectDate: string) => {
  try {
    const modelsConfig = await getModelsConfig();
    const modelId = modelRange;
    logger.debug(`Received models.json looking for ${modelId}`, { modelsConfig, modelRange });
    const foundModelConfig = modelsConfig?.filter((config) => {
      return (
        config.id.startsWith(modelId) &&
        config.effectTimeFrameFrom &&
        config.effectTimeFrameTo &&
        new Date(effectDate) >= new Date(config.effectTimeFrameFrom) &&
        new Date(effectDate) <= new Date(config.effectTimeFrameTo)
      );
    });

    if (!foundModelConfig?.length) {
      throw new Error(`Could not find model config for ${modelRange}`);
    }

    const nonBlackBadge = foundModelConfig?.filter((variant) => {
      return !variant.isBlackBadge;
    });

    return {
      ...(nonBlackBadge?.length ? nonBlackBadge[0] : foundModelConfig[0]),
    };
  } catch (err: any) {
    logger.error(err.message, { modelRange });
    return null;
  }
};
