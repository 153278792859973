/**
 * Possible Languages within the Application
 */
export enum Language {
  EN = 'en',
  ZH = 'zh',
  JP = 'jp',
  KR = 'kr',
}

export interface Epaas {
  tenant: string;
  locale: Language;
}

export interface Environment {
  /**
   * Endpoint for getting user default lang
   */
  countryConfigPath: string;
  /**
   * Endpoint for the digital brochure api
   */
  apiEndpoint: string;
  /**
   * API key for the digital brochure api
   */
  apiKey: string;
  /**
   * Should this environment be seen as production
   */
  /**
   * assets configurations
   */
  assets: {
    baseUrl: string;
  };
  production: boolean;
  /**
   * Emergency cache buster to allow force refresh of assets.
   * should only be used in case of caching issues otherwise try cdn invalidation first
   */
  assetFilesCacheBuster: string;
  /**
   * Config for dataprotection solution ePaaS.
   */
  epaas: Epaas;
}
