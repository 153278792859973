import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { init as initEPaaS } from '@rrvis/epaas';
import { TranslateService } from '@ngx-translate/core';
import { FooterLink, FooterSocialLink } from '@rrvis/ui';
import { tap, switchMap } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '../environments/environment';
import { LocalService } from './services/local.service';
import { selectActiveTheme, selectConfigIdRef, selectThemeColours } from './state/selectors';
import { LanguageManagerService } from './services/language-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit, AfterViewInit {
  public footerLinks: FooterLink[];

  public footerSocialLinks: FooterSocialLink[];

  public nextLanguage: string;

  public theme$ = this.store.select(selectActiveTheme);
  public themeColours$ = this.store.select(selectThemeColours);
  public logoHref$ = this.store.select(selectConfigIdRef);

  public ctaButtonColor: string;
  public activeSelectionBorderColor: string;

  constructor(
    private router: Router,
    private store: Store,
    private languageService: LanguageManagerService,
    private translate: TranslateService,
    private localService: LocalService,
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        const lang = this.languageService.getUserSavedLanguage();
        if (!!event.url && event.url.match(/^\/#/) && !!lang) {
          this.router.navigateByUrl(`/${lang + event.url.replace('/#', '')}`);
        }
      }
    });

    this.translate.onLangChange
      .pipe(
        tap(({ lang }) => (this.nextLanguage = this.getUnusedLanguage(lang))),
        switchMap(({ lang }) => this.localService.getFooterConfig$(lang)),
      )
      .subscribe({
        next: ({ links, socialNetworks }) => {
          this.footerLinks = links;
          this.footerSocialLinks = socialNetworks;
        },
        error: () => {
          this.footerLinks = [];
          this.footerSocialLinks = [];
        },
      });
  }

  private getUnusedLanguage(currentLang: string): string {
    return this.languageService.SUPPORTED_LANGS.find((lang) => lang !== currentLang);
  }

  getWeChatQrCode() {
    return this.localService.getWeChatQrCode();
  }

  toggleLanguage(): void {
    const newLanguage = this.getUnusedLanguage(this.translate.currentLang);
    const prevLang = `${this.translate.currentLang}`;
    this.router.navigateByUrl(this.router.url.replace(prevLang, newLanguage), { replaceUrl: true, onSameUrlNavigation: 'reload' });
    // this.store.dispatch(TranslationActions.setLang({ lang: newLanguage }));
  }

  ngAfterViewInit(): void {
    if (environment.epaas.tenant) {
      initEPaaS({
        locale: environment.epaas.locale,
        tenant: environment.epaas.tenant,
      });
    }

    this.themeColours$.subscribe(({ activeSelectionBorderColor, ctaButtonColor }) => {
      this.activeSelectionBorderColor = activeSelectionBorderColor;
      this.ctaButtonColor = ctaButtonColor;
    });
  }
}
