import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ContentElement } from '@digital-brochure/services';

@Component({
  selector: 'app-debug-element',
  templateUrl: './debug-element.component.html',
  styleUrl: './debug-element.component.scss',
  host: { class: 'debug-element' },
  encapsulation: ViewEncapsulation.None,
})
export class DebugElementComponent {
  @Input() isDev: boolean = false;

  @Input() element?: ContentElement;

  stringify() {
    return JSON.stringify(this.element);
  }
}
