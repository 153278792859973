import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { initPolicyPage } from '@rrvis/epaas';
import { Router } from '@angular/router';
import { Theme } from '@rrvis/ui';
import { selectBrand } from '../../state/selectors';

@Component({
  selector: 'app-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
})
export class CookiePolicyComponent implements OnInit, AfterViewInit {
  @ViewChild('container') cookiePolicyContainer!: ElementRef;

  private readonly store = inject(Store);

  public brand$ = this.store.select(selectBrand);

  public theme = Theme.DARK;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    initPolicyPage(this.cookiePolicyContainer.nativeElement);
  }

  goBack(): void {
    this.router.navigate(['/']);
  }
}
