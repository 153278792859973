import { Injectable } from '@angular/core';

const storagePrefix = 'rr-db';

export enum STORAGE_ENTRIES {
  LANG = `${storagePrefix}-lang`,
}

const getValue = (str: string) => {
  if (!str) {
    return null;
  }

  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
};

@Injectable({
  providedIn: 'root',
})
export class StorageManagerService {
  public removeFromStorage(storageKey: STORAGE_ENTRIES) {
    sessionStorage.removeItem(storageKey);
  }

  public saveToStorage(storageKey: STORAGE_ENTRIES, value: string) {
    sessionStorage.setItem(storageKey, value);
  }

  public getFromStorage<DataType>(storageKey: STORAGE_ENTRIES): DataType {
    try {
      return getValue(sessionStorage.getItem(storageKey)!);
    } catch (err) {
      return null;
    }
  }
}
