import { NgModule, inject } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { RouterLanguageResolver } from './pages/router-language.resolver';
import { SplashComponent } from './pages/splash/splash.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { selectUserDefaultLang } from './state/selectors';
import { ErrorComponent } from './pages/error/error.component';

export const getConfigId = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('configId') || window.location.hash.replace(/^#\/?/, '');
};

const routes: Routes = [
  {
    path: 'cookie-policy',
    component: CookiePolicyComponent,
    canActivate: [
      (_route, _state) => {
        const store = inject(Store);
        const router = inject(Router);
        return store.select(selectUserDefaultLang).pipe(
          map((defaultLang) =>
            router.navigate([defaultLang, 'cookie-policy'], {
              queryParamsHandling: 'preserve',
            }),
          ),
        );
      },
    ],
  },
  {
    path: ':lang',
    resolve: {
      language: RouterLanguageResolver,
    },
    children: [
      {
        path: 'cookie-policy',
        component: CookiePolicyComponent,
      },
      {
        path: ':id',
        component: SplashComponent,
      },
      {
        path: '**',
        component: ErrorComponent,
      },
    ],
  },
  {
    path: '**',
    component: ErrorComponent,
    resolve: {
      language: RouterLanguageResolver,
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
