export interface ModelsConfig {
  id: string;
  derivativeId: string;
  visible: boolean;
  defaultEnvironment: string;
  allowStreaming: boolean;
  defaultModelCodeRHD: string;
  defaultModelCodeLHD: string;
  effectDate: Date;
  isBlackBadge?: boolean;
  themeColor?: string;
  effectTimeFrameFrom?: string;
  effectTimeFrameTo?: string;
}
export type ModelsConfigFile = ModelsConfig[];
