import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3';
import { logger } from '../logger';

const s3 = new S3Client({ region: 'eu-central-1' });

export const readContentFilesFromS3 = async <ResponseFile>(
  Key: string,
  ignoreMissing = false
): Promise<ResponseFile | null> => {
  try {
    const Bucket = process.env.VISUALISER_ASSETS_BUCKET_NAME || null;
    if (!Bucket) {
      logger.error('Missing Bucket name to fetch assets');
      throw Error('Missing assets bucket name env (VISUALISER_ASSETS_BUCKET_NAME)');
    }

    logger.debug('Read Content Files', { Key, Bucket });
    const getFileCmd = new GetObjectCommand({
      Bucket,
      Key,
    });
    const data = await s3.send(getFileCmd);
    if (!data.Body) {
      throw new Error('No data received');
    }
    const body = await data.Body?.transformToString('utf8');
    return JSON.parse(body.replace(/^\uFEFF/, '')) as ResponseFile;
  } catch (err: any) {
    if (!ignoreMissing) {
      logger.error('Failed to load content.', { Key, error: err.message });
    }
    return null;
  }
};
