<div *ngIf="loading && !error" [ngClass]="'app app--' + (brand$ | async) + ' app--light'">
  <app-loader *ngIf="loading"></app-loader>
</div>
<div *ngIf="error && !loading" [ngClass]="'app app--' + (brand$ | async) + ' app--light'">
  <app-error [variant]="error"></app-error>
</div>
<div *ngIf="!loading && !error" [ngClass]="'app app--' + (brand$ | async) + ' app--' + (theme$ | async)">
  <app-logo [overlayActive]="overlayActive$ | async"></app-logo>
  <app-content *ngIf="data != null" [meta]="data.meta" [content]="data.content"></app-content>
</div>
