import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import Glide from '@glidejs/glide';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss',
  host: { class: 'carousel' },
  encapsulation: ViewEncapsulation.None,
})
export class CarouselComponent implements OnDestroy, AfterViewInit {
  @Input() shots:
    | {
        image: string;
        caption: string;
      }[]
    | undefined;

  @ViewChild('carousel')
  slider!: ElementRef<HTMLElement>;

  CAROUSEL_AUTOPLAY_DURATION = 7500;

  glideInstance: any;

  constructor() {}

  ngAfterViewInit(): void {
    this.glideInstance = new Glide(this.slider.nativeElement, {
      type: 'carousel',
      gap: 0,
      focusAt: 'center',
      animationTimingFunc: 'cubic-bezier(0.64, 0.55, 0.34, 0.98)',
      animationDuration: 1000,
      autoplay: this.CAROUSEL_AUTOPLAY_DURATION,
      hoverpause: false,
      classes: {
        // @ts-expect-error works as expected
        activeSlide: 'carousel__slide--active',
        activeNav: 'carousel__slide--active',
      },
      peek: 300,
      breakpoints: {
        600: {
          peek: 0,
        },
        800: {
          peek: 16,
        },
        1200: {
          peek: 200,
        },
      },
    });

    // add transition which is smiliar to the website.
    this.glideInstance.on('run', () => {
      if (!this.slider.nativeElement) {
        return;
      }

      this.slider.nativeElement.querySelectorAll<HTMLElement>(`li[data-slide-index]`).forEach((item) => {
        const slideItem = item;
        slideItem.style.transform = 'scale(0.8)';
      });

      this.slider.nativeElement.querySelectorAll<HTMLElement>(`[data-slide-index='${this.glideInstance.index}']`).forEach((item) => {
        const slideItem = item;
        slideItem.style.transform = 'scale(1)';
      });

      this.slider.nativeElement.querySelectorAll<HTMLElement>(`[data-glide-content]`).forEach((item) => {
        const slideItem = item;
        slideItem.style.opacity = '0';
        slideItem.style.transition = 'none';
      });

      this.slider.nativeElement.querySelectorAll<HTMLElement>(`[data-glide-content="${this.glideInstance.index}"]`).forEach((item) => {
        const slideItem = item;
        slideItem.style.opacity = '1';
        slideItem.style.transition = 'opacity 1.25s ease-in';
      });
    });

    this.glideInstance.mount();
  }

  ngOnDestroy(): void {
    this.glideInstance.destroy();
  }

  goToPrevious(): void {
    if (this.slider) {
      (this.slider.nativeElement as any).go('<');
    }
  }

  goToNext(): void {
    if (this.slider) {
      (this.slider.nativeElement as any).go('>');
    }
  }
}
