import { DateTime, Interval } from 'luxon';
import { get } from 'lodash';
import { logger } from './logger';
import { EmissionDisclaimer, FootnotesAdditionalData } from './types';
import {
  DigitalBrochureEmissionsData,
  DigitalBrochureEmissionsSourceType,
  EmissionsLayoutResponse,
  EmissionsServiceApiPayload,
  EmissionsSourceType,
} from './interfaces/emissions';
import { fetchFromVisualiserApi } from './visualiser-api';

/** uses https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2 */
export const countryAllowList = [
  'CY',
  'LU',
  'PL',
  'PT',
  'AT',
  'BE',
  'CZ',
  'FI',
  'DE',
  'NL',
  'UK',
  'DK',
  'FR',
  'GR',
  'IE',
  'IT',
  'LT',
  'MC',
  'NO',
  'RO',
  'ES',
  'SE',
  'CH',
];

export const isValidEmissionServiceResponse = (response?: unknown): response is EmissionsLayoutResponse => {
  return (
    typeof response === 'object' &&
    Object.prototype.hasOwnProperty.call(response, 'overview') &&
    Object.prototype.hasOwnProperty.call(response, 'source')
  );
};

export const mapEmissionsDisclaimer = (
  additionalData: FootnotesAdditionalData,
  emissionSource: EmissionsSourceType | DigitalBrochureEmissionsSourceType,
  validFrom?: DateTime
) => {
  const disclaimers = get(additionalData, `emissionDisclaimer.${emissionSource}`, {}) as EmissionDisclaimer;
  const disclaimerValidFromYears = Object.keys(disclaimers)
    .map((date) => DateTime.fromFormat(date, 'dd.mm.yyyy'))
    .sort((a, b) => (a < b ? -1 : 1));
  const disclaimerYear = disclaimerValidFromYears.find((disclaimerDate, index) => {
    if (!validFrom) {
      return false;
    }
    if (validFrom <= disclaimerDate) {
      // if the valid date is from before the earliest then return the earliest
      return validFrom.equals(disclaimerDate) || index === 0;
    }
    if (index + 1 >= disclaimerValidFromYears.length) {
      // valid from is far in the future so use last index
      return true;
    }
    if (Interval.fromDateTimes(disclaimerDate, disclaimerValidFromYears[index + 1]).contains(validFrom)) {
      // is it between this and the next one.
      return true;
    }
    return false;
  });
  if (disclaimerYear) {
    return disclaimers[disclaimerYear?.toFormat('dd.mm.yyyy').toString()];
  }
  return [];
};

export const getEmissionsData = async (payload: EmissionsServiceApiPayload): Promise<DigitalBrochureEmissionsData> => {
  try {
    logger.debug('Loading emissions data', { payload });
    const data = await fetchFromVisualiserApi<EmissionsLayoutResponse>('emissions', { ...payload, raw: false });
    logger.debug('Received from Emission Service data', { data });
    if (!isValidEmissionServiceResponse(data)) {
      logger.error('Received from Emission Service data', { data });
      throw new Error(`Not a valid response`);
    }

    return {
      ...data,
      validFrom: DateTime.fromFormat(data.usedComputationDate, 'dd.mm.yyyy'),
    };
  } catch (err: any) {
    logger.info('Use fallback for emission service', { msg: err.message });
    return { source: DigitalBrochureEmissionsSourceType.FAILED };
  }
};
