import { Component, HostListener, Input, ViewEncapsulation } from '@angular/core';
import { VehicleData } from '@digital-brochure/services';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrl: './logo.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class LogoComponent {
  @Input() overlayActive?: boolean;

  @Input() vehicleData?: VehicleData;

  public modifier: string = 'top';

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll() {
    if (window.scrollY > window.innerHeight/2) {
      this.modifier = 'hidden';
    } else if (window.scrollY > 1) {
      this.modifier = 'scrolled';
    } else {
      this.modifier = 'top';
    }
  }
}
