import { VehicleDataProps, VehicleDataResult, Status, EveResolverError } from './types';
import { getEveData } from './eve';
import { getContent } from './content';
import { getSpecifications } from './specifications';
import { logger } from './logger';
import { getEmissionsData } from './emissions';
import { modelRangeToModelVariant } from './helper/derivative';
import { METRICS } from './metrics';
import { getSplitterImages } from './splitter';
import { CountryCode } from './configurator';
import { Variant } from './helper/model.interface';

export const getVehicleData = async ({
  configId,
  language,
  brand,
  country,
}: VehicleDataProps): Promise<VehicleDataResult> => {
  let logInfoData = {};
  try {
    const { isBlackBadge, modelRange, agModelCode, images, videos, featureCodes, effectDate, market } =
      await getEveData(configId);

    logInfoData = {
      market,
      modelRange,
      effectDate,
      language,
      country,
    };

    // For both events, the market, client,
    // model range, the effective date,
    // country from where the request came, and language requested are logged.
    logger.info(METRICS.BROCHURE_INVOCATION, logInfoData);

    const modelVariant = await modelRangeToModelVariant(modelRange, effectDate);

    if (!modelVariant) {
      throw new Error('MissingVariantConfig');
    }

    const emissions = await getEmissionsData({
      binding: false,
      configuration: featureCodes,
      countryCode: market,
      derivativeId: modelVariant.derivativeId, //  "Phantom"
      modelCode: agModelCode, // "TU41"
      modelId: modelVariant.id, // "RR21"
      productionDate: effectDate,
    });

    const specs = await getSpecifications({
      modelVariant: modelVariant as unknown as Variant,
      modelRange,
      agModelCode,
      featureCodes,
      isBlackBadge,
      language,
    });

    const featureSplitter = await getSplitterImages(
      modelRange,
      agModelCode,
      featureCodes,
      modelVariant.derivativeId,
      modelVariant.id,
      isBlackBadge,
      language
    );

    const content = await getContent(
      language,
      country as CountryCode,
      isBlackBadge,
      modelVariant as unknown as Variant,
      modelVariant.id,
      agModelCode,
      modelVariant.derivativeId, //  "Phantom"
      images,
      videos,
      specs,
      featureCodes,
      emissions
    );

    return {
      status: Status.OK,
      data: {
        meta: {
          blackBadge: isBlackBadge,
          ctaButtonColor: modelVariant?.themeColor || 'white',
          activeSelectionBorderColor: modelVariant?.themeColor || '#6D30A7',
          configId,
          language,
          brand,
          ...featureSplitter,
        },
        content,
      },
    };
  } catch (e: any) {
    const logErrorData = {
      ...logInfoData,
      configId,
      brand,
    };

    switch (e.message as EveResolverError) {
      case 'NotFoundError':
        logger.error(METRICS.BROCHURE_ERROR, {
          ...logErrorData,
          error: 'Not found',
        });
        return {
          status: Status.NOT_FOUND,
        };
      case 'NotYetAvailable':
        logger.error(METRICS.BROCHURE_ERROR, {
          ...logErrorData,
          error: 'Not yet available',
        });
        return {
          status: Status.NOT_YET_AVAILABLE,
        };
      case 'ExpiredError':
        logger.error(METRICS.BROCHURE_ERROR, {
          ...logErrorData,
          error: 'Expired',
        });
        return {
          status: Status.EXPIRED,
        };
      case 'MissingVariantConfig':
        logger.error(METRICS.BROCHURE_MISSING_VARIANT_CONFIG, {
          ...logErrorData,
          error: 'Variant config not found',
        });
        return {
          status: Status.NOT_FOUND,
        };
      default:
        if (e.response?.status === 410) {
          logger.error(METRICS.BROCHURE_ERROR, {
            ...logErrorData,
            error: 'Expired',
          });
          return {
            status: Status.EXPIRED,
          };
        }
        logger.error(METRICS.BROCHURE_ERROR, {
          ...logErrorData,
          error: 'Unknown',
        });
        return {
          status: Status.ERROR,
        };
    }
  }
};
