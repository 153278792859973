import { Environment, Language } from './types';

export const environment: Environment = {
  countryConfigPath: 'https://rrvis-tst.aws.bmw.cloud/country/config',
  production: false,
  assetFilesCacheBuster: '0',
  epaas: {
    tenant: 'brochure_rolls-roycemotorcars_com',
    locale: Language.EN,
  },
  apiEndpoint: process.env.REACT_APP_API_ENDPOINT,
  apiKey: process.env.REACT_APP_API_KEY,
  assets: {
    baseUrl: '/assets/',
  },
};
