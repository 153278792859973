import axios from 'axios';

import { logger } from './logger';

export type VisualiserPayload = Record<string, unknown>;

export const fetchFromVisualiserApi = async <TVisualiserResponse>(endpoint: string, payload: VisualiserPayload) => {
  const apiTrustHeaderKey = process.env.VISUALISER_TRUST_KEY as string;
  if (!apiTrustHeaderKey && apiTrustHeaderKey === '') {
    logger.warn('No trust header provided. Request might be blocked by WAF.');
  }

  const response = await axios.post<TVisualiserResponse>(
    `${process.env.VISUALISER_API_ENDPOINT}/${endpoint}`,
    payload,
    {
      headers: {
        'rrvis-trust': apiTrustHeaderKey,
        'Content-Type': 'application/json',
        'User-Agent': 'digital-brochure',
      },
    }
  );

  logger.debug('Received Response', { status: response.status, data: response.data });
  return response.data;
};
