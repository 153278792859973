import { Language } from '../services/language-manager.service';
import { AppState, Theme } from './state.types';

export const initialState: AppState = {
  configId: '',
  brand: 'rollsroyce',
  lang: Language.EN,
  defaultLang: Language.EN,
  theme: Theme.LIGHT,
  overlayActive: true,
  ctaButtonColor: 'white',
  activeSelectionBorderColor: '#6D30A7',
};

export interface AppReducers {
  app: AppState;
}
