import { AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { GlobalActions } from '../../state/actions/global';

@Component({
  selector: 'app-hero-video',
  templateUrl: './hero-video.component.html',
  styleUrl: './hero-video.component.scss',
  host: { class: 'hero-video' },
  encapsulation: ViewEncapsulation.None,
})
export class HeroVideoComponent implements AfterViewInit, OnDestroy {
  @Input() title?: string;

  @Input() subline?: string;

  @Input() label?: string;

  @Input() startVideoLabel?: string;

  @Input() videoUrl?: string;

  @Input() posterUrl?: string;

  @Input() wordmark?: string;

  @Input() videoFallback?: string;

  @Input() setOverlayActive?: (active: boolean) => void;

  @ViewChild('wrapper') wrapperElement: ElementRef;

  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement>;

  public playing: boolean = false;

  public canPlay: boolean = false;

  private unlistenMetaData?: () => void;

  private unlistenTimeUpdate?: () => void;

  constructor(
    private renderer: Renderer2,
    private store: Store,
  ) {}

  ngAfterViewInit() {
    if (this.videoElement?.nativeElement) {
      this.unlistenMetaData = this.renderer.listen(this.videoElement.nativeElement, 'loadedmetadata', this.handleCanPlay.bind(this));
      this.unlistenTimeUpdate = this.renderer.listen(this.videoElement.nativeElement, 'timeupdate', () => {
        if (this.videoElement.nativeElement.currentTime >= 62.5) {
          this.videoElement.nativeElement.currentTime = 0;
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unlistenMetaData?.();
    this.unlistenTimeUpdate?.();
  }

  handleCanPlay(): void {
    this.canPlay = true;
  }

  playVideo(event: Event, status: boolean): void {
    event.stopPropagation();
    if (this.videoElement) {
      if (status) {
        window.scrollTo({
          top: this.wrapperElement.nativeElement.offsetTop || 0,
          behavior: 'smooth',
        });
        this.videoElement.nativeElement.play();
        this.playing = true;
        this.store.dispatch(GlobalActions.setOverlayActive({ active: false }));
      } else {
        this.videoElement.nativeElement.pause();
        this.playing = false;
        this.store.dispatch(GlobalActions.setOverlayActive({ active: true }));
      }
    }
  }
}
