import { DigitalBrochureEmissionsTable } from '@digital-brochure/services';
import { TranslateService } from '@ngx-translate/core';
import { MappedEmissionsData } from '@rrvis/ui';

export function mapEmissonsData(translate: TranslateService, data: DigitalBrochureEmissionsTable[]): MappedEmissionsData {
  return {
    tables: data
      .map((table) => {
        if (!table) {
          return null;
        }
        const translatedHeaders = table.headers.map((header) => header && translate.instant(header));
        const translatedRows = table.rows.map((row) => row.map((entry) => translate.instant(entry.translationKey, entry.data)));

        return {
          header: translatedHeaders,
          rows: translatedRows,
        };
      })
      .map((table) => table),
  } as MappedEmissionsData;
}
