import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { LanguageManagerService } from '../services/language-manager.service';
import { TranslationActions } from '../state/actions/translation';

@Injectable({ providedIn: 'root' })
export class RouterLanguageResolver implements Resolve<any> {
  constructor(
    private store: Store,
    private router: Router,
    private languageService: LanguageManagerService,
    private location: Location,
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    const lang = route.paramMap.get('lang');
    if (this.languageService.SUPPORTED_LANGS.some((sLang) => sLang === lang)) {
      this.store.dispatch(TranslationActions.setLang({ lang: lang! }));
      return lang;
    }

    const currentPath = this.location.path(true).split('/').filter(Boolean).slice(1);
    return this.languageService.getUserLanguageFromConfig().pipe(
      tap((configResponse) => {
        const defaultLang = configResponse.defaultLang;
        this.store.dispatch(TranslationActions.setLang({ lang: defaultLang }));
        return this.router.navigate([defaultLang, ...currentPath], {
          queryParamsHandling: 'preserve',
        });
      }),
    );
  }
}
