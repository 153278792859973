import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-headline-block',
  templateUrl: './headline-block.component.html',
  styleUrl: './headline-block.component.scss',
  host: { class: 'headline-block container container--small' },
  encapsulation: ViewEncapsulation.None,
})
export class HeadlineBlockComponent {
  @Input() title?: string;
}
