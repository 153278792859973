import { Language } from '../types';

export enum BrochureConfigFile {
  BRICKS = 'bricks',
  STRUCTURE = 'structure',
  FALLBACK = '',
}

export const getMainFilePath = (language: Language, derivative: string, modelRange: string) =>
  `assets/data/${derivative}/${modelRange}/brochure/main_${language}.json`;

export const getBrochureFilePath = (
  language: Language,
  file: BrochureConfigFile,
  derivative: string,
  modelRange: string,
  modelCode = 'main'
) => {
  const fileName = file === BrochureConfigFile.FALLBACK ? '' : `_${file}`;
  return `assets/data/${derivative}/${modelRange}/brochure/${modelCode}_${language}${fileName}.json`;
};

export const getSpecificFilePath = (language: Language, derivative: string, modelRange: string, agModelCode: string) =>
  `assets/data/${derivative}/${modelRange}/brochure/${agModelCode}_${language}.json`;

export const getVdmlFilePath = (agModelCode: string, isBlackBadge: boolean) =>
  `assets/summary/${agModelCode}/${agModelCode.startsWith('XZ') && isBlackBadge ? `tree_bb` : 'tree'}.json`;

export const getUIFilePath = (derivative: string, modelRange: string) =>
  `assets/data/${derivative}/${modelRange}/brochure/ui.json`;

export const getFooterFilePath = (language: Language) => `assets/data/footer-${language}.json`;

export const getColorCodePath = () => 'assets/summary/colour_code_map.json';

export const getModelsConfigFilePath = () => 'assets/data/models.json';
export const getLanguageFile = (lang: Language) => `assets/i18n/${lang}.json`;
