import { fixBrochureImportCodes, tidyConfiguratorCodeForGarage } from '@rrvis/code-mapper';
import { Variant } from './helper/model.interface';

export enum CountryCode {
  NorthAmerica = 'US',
  CN = 'CN',
  LU = 'LU',
  DE = 'DE',
}

export enum Language {
  EN = 'en',
  ZH = 'zh',
  JP = 'jp',
  KR = 'kr',
}

export type OpenConfiguratorParams = {
  isBlackBadge: boolean;
  activeVariant: Variant;
  language: string;
  userCountry: CountryCode;
  featureCodes: string[];
  openNewTab?: boolean;
};

const getModelCode = (country: CountryCode, variant: Variant) => {
  if (country === CountryCode.NorthAmerica) {
    return variant.defaultModelCodeUS;
  } else if (country === CountryCode.CN && variant.alternativeModelCodesLHD[0]) {
    return variant.alternativeModelCodesLHD[0];
  }
  return variant.defaultModelCodeLHD;
};

const getUserCountry = (userCountry: CountryCode) => {
  if (userCountry === CountryCode.NorthAmerica || userCountry === CountryCode.CN) {
    return userCountry;
  }
  return CountryCode.LU;
};

const getLanguage = (currentLanguage: string, userCountry: CountryCode): string => {
  if (userCountry !== CountryCode.CN) {
    return Language.EN;
  }
  return currentLanguage;
};

export function createConLink({
  activeVariant,
  isBlackBadge,
  language,
  userCountry,
  featureCodes,
}: OpenConfiguratorParams) {
  const modelRange = activeVariant.modelRange || activeVariant.id.slice(0, 4);
  const range = isBlackBadge ? modelRange + 'BB' : modelRange;
  const configuratorURL = process.env.CONFIGURATOR_URL;
  const country = getUserCountry(userCountry);
  const configuratorLang = getLanguage(language, country);
  const modelCode = getModelCode(country, activeVariant);
  const configuration = tidyConfiguratorCodeForGarage(fixBrochureImportCodes(featureCodes)).join(',');

  return `${configuratorURL}/${configuratorLang}_${country}/configure/${range}/${modelCode}/${configuration}`;
}
