import { Injectable } from '@angular/core';
import { Brand, Language, Status, VehicleDataResult, isValidLanguage } from '@digital-brochure/services';
import { environment } from '../../environments/environment';
import { ErrorVariant } from '../pages/error/error.component';

export const getConfigId = (): string => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('id') || window.location.hash.replace(/^#\/?/, '');
};

export const getLanguage = (): Language => {
  const urlLang = window.location.pathname.split('/').filter(Boolean);
  if (urlLang.length > 0) {
    if (isValidLanguage(urlLang[0])) {
      return urlLang[0];
    }
  }
  const language = navigator.language.substr(0, 2);
  if (isValidLanguage(language)) {
    return language;
  }
  return 'en';
};

export const getBrand = (): Brand => {
  // Static for now
  return 'rollsroyce';
};

@Injectable({
  providedIn: 'root',
})
export class ConfigurationDataService {
  constructor() {}

  async getData(lang: string, configId: string) {
    try {
      const url = new URL(environment.apiEndpoint);
      const apiKey = environment.apiKey || 'no set';
      url.searchParams.append('configId', configId || getConfigId());
      url.searchParams.append('language', lang);
      url.searchParams.append('brand', getBrand());

      const response = await fetch(url.toString(), {
        headers: {
          'x-api-key': apiKey,
        },
      });

      const { status, data } = (await response.json()) as VehicleDataResult;
      let error;
      switch (status) {
        case Status.NOT_YET_AVAILABLE:
          error = ErrorVariant.NOT_YET_AVAILABLE;
          break;
        case Status.NOT_FOUND:
          error = ErrorVariant.NOT_FOUND;
          break;
        case Status.EXPIRED:
          error = ErrorVariant.EXPIRED;
          break;
        case Status.OK:
          error = null;
          break;
        default:
          error = ErrorVariant.GENERIC;
          break;
      }
      return { data, error };
    } catch {
      return { error: ErrorVariant.GENERIC, data: null };
    }
  }
}
