import { NgModule } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ENVIRONMENT_MODE, EnvironmentMode, UtilModule } from '@rrvis/util';

import { BrowserModule } from '@angular/platform-browser';
import { ASSETS_BASE_URL, IMAGES_BASE_URL, UiModule } from '@rrvis/ui';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorBlockComponent } from './components/error-block/error-block.component';
import { TextImageComponent } from './components/text-image/text-image.component';
import { TextBlockComponent } from './components/text-block/text-block.component';
import { TeaserComponent } from './components/teaser/teaser.component';
import { ScrollIndicatorComponent } from './components/scroll-indicator/scroll-indicator.component';
import { LogoComponent } from './components/logo/logo.component';
import { LoaderComponent } from './components/loader/loader.component';
import { HeroVideoComponent } from './components/hero-video/hero-video.component';
import { HeroImageComponent } from './components/hero-image/hero-image.component';
import { HeadlineBlockComponent } from './components/headline-block/headline-block.component';
import { ContentComponent } from './components/content/content.component';
import { ColorIconComponent } from './components/color-icon/color-icon.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { CookiePolicyComponent } from './pages/cookie-policy/cookie-policy.component';
import { LangComponent } from './pages/lang/lang.component';
import { SplashComponent } from './pages/splash/splash.component';
import { FootNotesComponent } from './components/foot-notes/foot-notes.component';
import { LanguageEffect } from './state/effects/language.effect';
import { ErrorComponent } from './pages/error/error.component';
import { DebugElementComponent } from './components/debug-element/debug-element.component';
import { appReducer } from './state/reducer';
import { RRLogoSvgComponent } from './components/logo/rr-logo.component';
import { PlaySvgComponent } from './components/hero-video/play.component';
import { AccordionComponent } from './components/accordion/accordion.component';
import { FooterComponent } from './components/footer/footer.component';
import { ASSETS_BASE_PATH } from './injection-tokens';
import { CTAGroupComponent } from './components/cta-group/cta-group.component';

const effects: any[] = [LanguageEffect];

@NgModule({
  declarations: [
    AppComponent,
    AccordionComponent,
    ErrorComponent,
    TextImageComponent,
    TextBlockComponent,
    TeaserComponent,
    ScrollIndicatorComponent,
    LogoComponent,
    RRLogoSvgComponent,
    PlaySvgComponent,
    LoaderComponent,
    HeroVideoComponent,
    HeroImageComponent,
    HeadlineBlockComponent,
    DebugElementComponent,
    ContentComponent,
    ColorIconComponent,
    CarouselComponent,
    CookiePolicyComponent,
    LangComponent,
    SplashComponent,
    ErrorBlockComponent,
    FootNotesComponent,
    AccordionComponent,
    FooterComponent,
    CTAGroupComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(
      { app: appReducer },
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
          strictStateSerializability: false,
          strictActionSerializability: false,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true,
        },
      },
    ),
    StoreDevtoolsModule.instrument({
      maxAge: 50,
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => new TranslateHttpLoader(http, '/assets/i18n/', `.json?v=${environment.assetFilesCacheBuster}`),
        deps: [HttpClient],
      },
    }),
    EffectsModule.forRoot(effects),
    UtilModule,
    UiModule,
  ],
  providers: [
    { provide: ASSETS_BASE_PATH, useValue: environment.assets.baseUrl },
    { provide: ASSETS_BASE_URL, useValue: '/styles' },
    { provide: IMAGES_BASE_URL, useValue: '/svg' },
    { provide: ENVIRONMENT_MODE, useValue: EnvironmentMode.DEVELOPMENT },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  public constructor(private store: Store) {}
}
