import {
  extractGenericColourFromBundleFeatures,
  isCustomStateCode,
  isGenericExteriorCode,
  isGenericInteriorCode,
  isSpecialOrderColorCode,
  isSplitFeatureCode,
  mapCustomStateCode,
  splitFeatureCode,
  splitSpecialOrderCode,
} from '@rrvis/code-mapper';
import { logger } from './logger';
import {
  getTranslationsFile,
  isContainedFeature,
  isVisibleFeature,
  readColorFile,
  readUIFile,
  readVDMLFile,
  resolveColorHexCode,
  resolveFeature,
  splitCode,
} from './helper/feature';

import { Bundle, ExternalVehicleData, FeaturesFile, Languages, Specification, TranslationFile } from './types';
import { configurationCodeToVisualiserCode } from './helper/configuration';

export type FeatureSplitter = {
  splitterImages: Record<string, string>;
  genericColours: Record<string, string>;
};

export type SplitterImages = {
  [colorKey: string]: string;
};

function encodeSpecialChars(text: string) {
  return encodeURIComponent(text).replace('~', '%7e');
}

export function isSplitCode(input: string) {
  return input.includes('SPLIT');
}

export function isEqualFeatureCode(source: Specification, input: string): boolean {
  if (input.startsWith(source.code)) {
    if (!isSplitFeatureCode(source.code) && isSplitFeatureCode(input)) {
      return false;
    }
    return true;
  }
  return false;
}

export function getSplitterImagePath(derivativeId: string, variantId: string, fileName: string) {
  if (!fileName) {
    return null;
  }
  return `/assets/data/${derivativeId}/${variantId}/splitters/${encodeSpecialChars(fileName)}.png`;
}

const getColorCode = (featureCode) => {
  if (!featureCode) {
    return '';
  }

  if (isSpecialOrderColorCode(featureCode)) {
    const fragments = splitSpecialOrderCode(featureCode);
    return `${fragments.specialCode}_${fragments.color}`;
  }
  return splitFeatureCode(featureCode).color;
};

export const getReferencedSplitterName = (
  referenceBundle: Bundle,
  featureCode: string,
  referenceCode,
  colorType: string
) => {
  const foundCode = referenceBundle?.featureCodes.find((code) => code.startsWith(referenceCode));
  /*
  If splitter is reference ex. @LL1 and no featureCode we wrongly get only -> (ZZ1EXTM1.png)
  we must get generic colour mask image prefix to have the full name ex. SVGEXT_ZZ1EXTM1.png
  */
  let genColourPrefix = '';
  if (!featureCode && (isGenericExteriorCode(foundCode) || isGenericInteriorCode(foundCode))) {
    genColourPrefix = `${referenceBundle.splitterName.join('-').split('_')[0]}_`;
  }
  //----

  return `${featureCode || genColourPrefix}${getColorCode(foundCode)}${colorType ? `-${colorType}` : ''}`;
};

export const getReferencedBundle = (
  selectorBundle: Bundle,
  allSelectedBundles: Bundle[]
): { bundle: Bundle; splitter: string } => {
  if (!selectorBundle?.splitterName || !selectorBundle?.splitterName[0]?.includes('@')) {
    return {
      bundle: selectorBundle,
      splitter: selectorBundle?.splitterN ?? '',
    };
  }
  const [featureSplitterName = '', colorTypeString = ''] = selectorBundle.splitterName;

  const [feature, reference] = featureSplitterName.split('@');
  const bundle = allSelectedBundles.find((b) => b.featureCodes.some((code) => code.startsWith(reference)));
  const splitter = getReferencedSplitterName(bundle, feature, reference, colorTypeString);

  if (splitter?.includes('@')) {
    return getReferencedBundle(bundle, allSelectedBundles);
  }
  return {
    bundle,
    splitter,
  };
};

const normalizeBundle = (bundle: Bundle) => {
  if (bundle.splitterName) {
    bundle.splitterN = bundle.splitterName.join('-');
    bundle.isCheckbox = bundle.splitterN.toLowerCase() === 'checkbox';
  } else {
    if (isCustomStateCode(bundle.featureCodes[0])) {
      bundle.splitterN = mapCustomStateCode(bundle.featureCodes[0]);
    } else {
      bundle.splitterN = bundle.featureCodes?.[0];
    }
    bundle.isCheckbox = false;
  }
  return bundle;
};

const getAvailableFeatures = (
  featureCodes: any,
  features: FeaturesFile,
  colors: TranslationFile,
  translation: Record<string, string>
) =>
  featureCodes
    .map(splitCode)
    .map((feature) => ({
      ...feature,
      colorHexCode: resolveColorHexCode(feature.colorCode, colors),
    }))
    .map(({ featureCode, colorCode, colorHexCode }) => ({
      ...resolveFeature(translation, featureCode, features, featureCodes),
      colorCode,
      colorHexCode,
    }))
    .filter(isVisibleFeature)
    .filter(isContainedFeature(featureCodes));

export const getSplitterImages = async (
  modelRange,
  agModelCode,
  featureCodes,
  derivative,
  variantId,
  isBlackBadge,
  language
): Promise<FeatureSplitter> => {
  try {
    const [{ en, zh, features }, colors, i18n, vehicleData] = await Promise.all([
      await readVDMLFile(agModelCode, isBlackBadge),
      await readColorFile(),
      await getTranslationsFile(language),
      await readUIFile(derivative, modelRange),
    ]);

    const treeTranslation = language === Languages.ZH ? zh : en;
    const translation: Record<string, string> = { ...treeTranslation, ...i18n.en, ...i18n.zh };

    const normalizedVehicleData = {
      groups: vehicleData.groups.map((group) => ({
        ...group,
        subgroups: group.subgroups.map((subgroup) => ({
          ...subgroup,
          bundles: subgroup.bundles.map((bundle) => ({ ...normalizeBundle(bundle) })),
        })),
      })),
    };

    const { finalBundles } = configurationCodeToVisualiserCode(
      normalizedVehicleData as unknown as ExternalVehicleData,
      featureCodes
    );

    const genericColoursMap = getAvailableFeatures(featureCodes, features, colors, translation).reduce(
      (acc: Record<string, string>, feature: Specification) => {
        const bundle = finalBundles.find((selectedBundle) => {
          return selectedBundle.featureCodes.some((f) => isEqualFeatureCode(feature, f));
        });
        if (!bundle || bundle.isCheckbox) {
          return acc;
        }

        const ref = getReferencedBundle(bundle, finalBundles);
        const [, colourCode] = feature.colorCode.split('_#');
        if (colourCode) {
          acc[feature.code] = `#${colourCode}` || ref.bundle.defaultHEXValue;
          ref.bundle.featureCodes.forEach((code) => {
            if (isGenericExteriorCode(code) || isGenericInteriorCode(code)) {
              acc[code] = `#${colourCode}` || ref.bundle.defaultHEXValue;
            }
          });
        }
        return acc;
      },
      {}
    );

    const resolvedFeatures = getAvailableFeatures(featureCodes, features, colors, translation).reduce(
      (acc: SplitterImages, feature: Specification) => {
        const bundle = finalBundles.find((selectedBundle) => {
          return selectedBundle.featureCodes.some((f) => isEqualFeatureCode(feature, f));
        });
        if (!bundle || bundle.isCheckbox) {
          return acc;
        }

        const ref = getReferencedBundle(bundle, finalBundles);
        const splitterImage = getSplitterImagePath(derivative, variantId, ref.splitter);
        const colour = extractGenericColourFromBundleFeatures(ref.bundle, genericColoursMap);
        acc.splitterImages[feature.code] = splitterImage;
        if (colour?.length) {
          acc.genericColours[feature.code] = colour;
        }

        return acc;
      },
      { splitterImages: {}, genericColours: {} } as FeatureSplitter
    );
    return resolvedFeatures;
  } catch (e) {
    logger.error('Failed to get specifications', { e });
    throw new Error('MissingSpecificationsError');
  }
};
