import { Inject, Injectable } from '@angular/core';
import { FooterData } from '@digital-brochure/services';
import { fromFetch } from 'rxjs/fetch';
import { Observable, defer, shareReplay, switchMap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { ASSETS_BASE_PATH } from '../injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class LocalService {
  private cache$: { [key: string]: Observable<any> } = {};

  private cacheBuster = environment.assetFilesCacheBuster;

  constructor(@Inject(ASSETS_BASE_PATH) private assetsBasePath: string) {}

  private mapErrorResponse = (error: any, response: Response) => {
    const errorResponse = { data: error, response };

    if (response.status >= 500) {
      errorResponse.data = { data: null, message: 'We are facing some technical difficulty' };
    }

    return throwError(() => errorResponse);
  };

  private getAssets$<A>(path: string, fallback?: string): Observable<A> {
    const url = `${path}?v=${this.cacheBuster}`;
    if (!this.cache$[url]) {
      this.cache$[url] = defer(() => fromFetch(url)).pipe(
        switchMap((response) => {
          const isJsonContentType = response.headers.get('content-type').includes('application/json');
          if (response.ok && response.status >= 200 && response.status < 300 && isJsonContentType) {
            return response.json();
          }
          if (!fallback) {
            return this.mapErrorResponse(response, response);
          }
          return this.getAssets$(fallback);
        }),
        shareReplay({ refCount: false, bufferSize: 1 }),
      );
    }
    return this.cache$[url];
  }

  getWeChatQrCode() {
    return `${this.assetsBasePath}img/we-chat-qrcode.png?v=${this.cacheBuster}`;
  }

  getFooterConfig$(lang: string): Observable<FooterData> {
    return this.getAssets$(`${this.assetsBasePath}data/footer-${lang}.json`);
  }
}
