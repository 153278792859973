<div class="glide" #carousel>
  <button class="carousel__control control__prev" (click)="(goToPrevious)">Previous</button>
  <button class="carousel__control control__next" (onClick)="(goToNext)">Next</button>
  <div class="carousel__wrapper">
    <div data-glide-el="track" class="carousel__track glide__track">
      <ul class="carousel__slides glide__slides">
        <li class="carousel__item glide__slide" *ngFor="let shot of shots; index as index" [attr.data-slide-index]="index">
          <img [src]="shot.image" [alt]="shot.caption" class="carousel__img" />
        </li>
      </ul>
    </div>
  </div>
  <div class="carousel__progress-wrapper" data-glide-el="controls[nav]">
    <button *ngFor="let shot of shots; index as index" class="carousel__progress-circle" [attr.data-glide-dir]="'=' + index">
      <svg xmlns="http://www.w3.org/2000/svg" class="carousel__progress-circle-icon" viewBox="0 0 18 18" width="16" height="16">
        <title>{{ shot.caption }}</title>
        <circle cx="9" cy="9" r="3.2" class="carousel__circle" />
        <path
          strokeWidth="1.5"
          fill="transparent"
          class="carousel__path"
          [style]="{ animationDuration: CAROUSEL_AUTOPLAY_DURATION * 2 + 'ms' }"
          d="M9 1.121A7.877 7.877 0 0116.879 9 7.877 7.877 0 019 16.879 7.877 7.877 0 011.121 9 7.877 7.877 0 019 1.121z"
        />
      </svg>
    </button>
  </div>
  <div class="carousel__description" data-glide-el="controls[nav]">
    <div *ngFor="let shot of shots; index as index" class="carousel__item" [attr.data-glide-content]="index">
      <p>{{ shot.caption }}</p>
    </div>
  </div>
</div>
