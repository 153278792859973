import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-teaser',
  templateUrl: './teaser.component.html',
  styleUrl: './teaser.component.scss',
  host: { class: 'teaser container' },
  encapsulation: ViewEncapsulation.None,
})
export class TeaserComponent {
  @Input() title?: string;

  @Input() paragraphs?: string[];

  @Input() image?: string;

  @Input() link?: { url: string; label: string };

  @Input() theme?: string = 'light';
}
