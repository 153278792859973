import {
  buildSpecialOrderCode,
  isGenericExteriorCode,
  isGenericInteriorCode,
  isReferenceCode,
  isSpecialOrderColorCode,
  isSplitFeatureCode,
  splitCodeSmart,
  splitFeatureCode,
  splitReferenceCode,
  splitSpecialOrderCode,
} from '@rrvis/code-mapper';
import { isEmpty } from 'lodash';
import {
  Bundle,
  ConfigurationCodeToVisualiserCodeResult,
  ExternalVehicleData,
  ExternalVehicleDataBundle,
} from '../types';
import { logger } from '../logger';
import { InspiredSpec, Variant } from './model.interface';
import {
  checkMatches,
  findBestMatchingBundlePerGroup,
  groupBundlesByGroupId,
  compareSpecs,
} from './configuration-helper';

interface FindMatchingVariant {
  variants: Variant[];
  activeVariant?: Variant;
  configurations: string[];
  modelCode: string;
  modelRange?: string;
}

export type ExternalVehicleDataBundleWithGroupId = ExternalVehicleDataBundle & {
  groupId: string;
  bundleId: string;
};

function findReferedCodeInConfigurationCodes(configurationCodes: string[], featureCode: string) {
  const referedFeatureCode = splitReferenceCode(featureCode);
  const referencedCode = configurationCodes.find((code) => code.startsWith(referedFeatureCode.reference));
  if (referencedCode && isReferenceCode(referencedCode)) {
    return findReferedCodeInConfigurationCodes(configurationCodes, featureCode);
  }
  return referencedCode;
}

const findReferenceBundle = (
  bundles: ExternalVehicleDataBundleWithGroupId[],
  configCode: string,
  configurationCodes: string[]
): ExternalVehicleDataBundleWithGroupId[] => {
  const { feature, color } = splitCodeSmart(configCode);
  return bundles
    .map((refBundle) => {
      const featureToCheck = isSplitFeatureCode(feature) ? feature?.split('_')[0] : feature;
      const code = refBundle.featureCodes.find((fCode) => fCode.startsWith(featureToCheck));
      const referencedCode = findReferedCodeInConfigurationCodes(configurationCodes, code);
      if (referencedCode && splitCodeSmart(referencedCode).color === color) {
        return refBundle;
      }
      return undefined;
    })
    .filter(Boolean);
};

export function configurationCodeToVisualiserCode(
  data: ExternalVehicleData,
  configurationCodes: string[],
  removeUnknownFeatureCodes = true
): ConfigurationCodeToVisualiserCodeResult {
  const minusCodes = [];
  const notFoundFeatures = [];
  const genericColourFeatures = {};

  const foundBundles = configurationCodes
    .sort()
    .flatMap((configCode): ExternalVehicleDataBundleWithGroupId => {
      const { feature, color } = splitCodeSmart(configCode);

      const configCodeIsGenericColourFeature = isGenericExteriorCode(configCode) || isGenericInteriorCode(configCode);
      const configCodeIsSplitFeature = isSplitFeatureCode(feature);

      const references: ExternalVehicleDataBundleWithGroupId[] = [];
      const specialOrders: ExternalVehicleDataBundleWithGroupId[] = [];
      const matching: ExternalVehicleDataBundleWithGroupId[] = [];
      const genericColours: ExternalVehicleDataBundleWithGroupId[] = [];

      // eslint-disable-next-line no-restricted-syntax, no-unsafe-optional-chaining
      for (const group of data?.groups) {
        // eslint-disable-next-line no-restricted-syntax
        for (const subGroup of group.subgroups) {
          // eslint-disable-next-line no-restricted-syntax
          for (const bundle of subGroup.bundles) {
            const featureToCheck = configCodeIsSplitFeature ? feature?.split('_')[0] : feature;

            const matchingFeatureCode = bundle.featureCodes.find((fCode) => {
              if (configCodeIsSplitFeature) {
                return fCode.startsWith(featureToCheck);
              }
              return fCode.startsWith(featureToCheck) && !isSplitFeatureCode(fCode);
            });

            if (matchingFeatureCode) {
              const bundleId = `${bundle.featureCodes.join('-')}`;
              const groupId = group.id;
              if (configCodeIsGenericColourFeature && !isReferenceCode(matchingFeatureCode)) {
                if (matchingFeatureCode === configCode || matchingFeatureCode === configCode.split('_#')[0]) {
                  genericColours.push({ ...bundle, bundleId, groupId });
                }
              } else if (isReferenceCode(matchingFeatureCode)) {
                references.push({ ...bundle, groupId, bundleId });
              } else if (isSpecialOrderColorCode(matchingFeatureCode)) {
                specialOrders.push({ ...bundle, groupId, bundleId });
              } else if (matchingFeatureCode === configCode) {
                matching.push({ ...bundle, groupId, bundleId });
              }
            }
          }
        }
      }

      if (genericColours.length) {
        const featureBundle = checkMatches(configurationCodes, genericColours);
        if (featureBundle) {
          const [code, colour] = configCode.split('_#');
          minusCodes.push(...featureBundle.minusCodes);
          genericColourFeatures[code] = `#${colour}` || featureBundle.defaultHEXValue;
          return featureBundle;
        }
      }

      if (references.length > 0) {
        const featureBundle = checkMatches(
          configurationCodes,
          findReferenceBundle(references, configCode, configurationCodes)
        );
        if (featureBundle) {
          return featureBundle;
        }
      }

      if (specialOrders.length > 0 && color) {
        const configCodeAsSO = buildSpecialOrderCode(feature, color);
        const featureBundle = checkMatches(
          configurationCodes,
          specialOrders
            .map((bundle) => {
              if (bundle.featureCodes.find((fCode) => fCode === configCodeAsSO)) {
                return bundle;
              }
              return undefined;
            })
            .filter(Boolean)
        );

        if (featureBundle) {
          return featureBundle;
        }
      }

      if (matching.length > 0) {
        const featureBundle = checkMatches(configurationCodes, matching);

        if (featureBundle) {
          minusCodes.push(...featureBundle.minusCodes);
          return featureBundle;
        }
      }

      notFoundFeatures.push(configCode);
      if (removeUnknownFeatureCodes) {
        return undefined;
      }
      return {
        bundleId: configCode,
        featureCodes: [configCode],
        groupId: 'unknown',
      } as ExternalVehicleDataBundleWithGroupId;
    })
    .filter(Boolean);

  // if (!environment.production) {
  //   this.logger.warn('Could not find the bundles for following features', { notFoundFeatures });
  // }

  const finalBundles = findBestMatchingBundlePerGroup(configurationCodes, groupBundlesByGroupId(foundBundles));
  const visualiserString = finalBundles.flatMap(({ featureCodes }) => featureCodes);
  const bundleIds = finalBundles.flatMap(({ bundleId }) => bundleId);

  return {
    visualiserCodes: visualiserString,
    bundleIds,
    finalBundles: finalBundles as unknown as Bundle[],
    notFoundFeatures,
    genericColourFeatures,
  };
}

function findClosestMatchingInspiredSpec(configurationCodes: string[], inspiredSpecs: InspiredSpec[]): InspiredSpec {
  const checkMatches = (specIds: InspiredSpec[], index = 0, highestMatch = 0, highestSpecId: InspiredSpec = null) => {
    if (index > inspiredSpecs.length - 1) {
      return highestSpecId;
    }
    const inspiredSpecCodes = configurationCodes.some((code) => code.includes('@'))
      ? inspiredSpecs[index].visualiserCode
      : inspiredSpecs[index].configuratorCode;
    const totalMatches = compareSpecs(configurationCodes, inspiredSpecCodes);

    if (totalMatches > highestMatch) {
      return checkMatches(specIds, index + 1, totalMatches, specIds[index]);
    }
    return checkMatches(specIds, index + 1, highestMatch, highestSpecId);
  };
  return checkMatches(inspiredSpecs);
}

export function getMatchingSpec(variant: Variant, configurations: string[]) {
  const { visualiserCodes } = configurationCodeToVisualiserCode({ groups: variant.ui.groups }, configurations);
  return visualiserCodes && findClosestMatchingInspiredSpec(visualiserCodes, variant.importOnlyInspiredSpecs);
}

export function calculateDiffBetweenCodes(baseCodes: string[], otherCodes: string[]) {
  return baseCodes.reduce((acc, code) => {
    if (isSpecialOrderColorCode(code)) {
      const { feature, color } = splitSpecialOrderCode(code);

      const specialColorFindRegex = new RegExp(`${feature}_X.?_.*${color}`);
      if (
        otherCodes.includes(`${feature}_${color.replace('LX', '')}`) ||
        otherCodes.includes(`${feature}_${color}`) ||
        otherCodes.some((code) => code.match(specialColorFindRegex))
      ) {
        return acc;
      }
    }

    if (otherCodes.includes(code)) {
      return acc;
    }

    const { feature, color } = splitFeatureCode(code);
    const specialColorFindRegex = new RegExp(`${feature}_X.?\_.*${color}`);
    const featureAsSpecialOrder = color ? `${feature}_LX${color}` : feature;
    const featureAsHistoricSpecialOrder = color ? `${feature}_${color.replace('LX', '')}` : feature;

    if (
      otherCodes.some((code) => code.match(specialColorFindRegex)) ||
      otherCodes.includes(featureAsSpecialOrder) ||
      otherCodes.includes(featureAsHistoricSpecialOrder)
    ) {
      return acc;
    }

    acc.push(code);
    return acc;
  }, []);
}

export const codeIsUnknown = (droppedFeatures: string[]) => (code: string) => droppedFeatures.includes(code);

const findClosestMatchingModelBasedOnInspiredSpec = (variants: Variant[], configurations: string[]) => {
  const foundAllMatchingSpecs = variants.reduce((acc, variant) => {
    const matchedSpec = findClosestMatchingInspiredSpec(configurations, variant.importOnlyInspiredSpecs);
    if (matchedSpec) {
      acc.push(matchedSpec);
    }
    return acc;
  }, []);

  const matchedSpec = findClosestMatchingInspiredSpec(configurations, foundAllMatchingSpecs);
  return variants.find((variant) => variant.id === matchedSpec.variantId);
};

export const findMatchingVariant = ({
  variants,
  activeVariant,
  configurations,
  modelCode,
  modelRange,
}: FindMatchingVariant): Variant => {
  if (activeVariant?.associatedModelCodes?.includes(modelCode)) {
    logger.debug('[CONFIG IMPORT] Found variant because model code is matching current active variant', {
      modelCode,
      model: activeVariant.id,
    });
    return activeVariant;
  }

  if (!isEmpty(modelCode)) {
    const variantsBasedOnModelCode = variants.filter(({ associatedModelCodes }) =>
      associatedModelCodes.includes(modelCode)
    );

    if (!isEmpty(variantsBasedOnModelCode)) {
      if (variantsBasedOnModelCode.length === 1) {
        logger.debug('[CONFIG IMPORT] Found variant based on given model code', {
          modelCode,
          model: variantsBasedOnModelCode[0].id,
        });
        return variantsBasedOnModelCode[0];
      }

      const modelMatchedOnInspiredSpec = findClosestMatchingModelBasedOnInspiredSpec(
        variantsBasedOnModelCode,
        configurations
      );
      if (!isEmpty(modelMatchedOnInspiredSpec)) {
        logger.debug('[CONFIG IMPORT] Found variant based on given model code and inspired spec', {
          modelCode,
          model: modelMatchedOnInspiredSpec.id,
        });
        return modelMatchedOnInspiredSpec;
      }
    }
  }

  if (!isEmpty(modelRange)) {
    const variantsBasedOnModelRange = variants.filter((model) => model.modelRange === modelRange);

    if (!isEmpty(variantsBasedOnModelRange)) {
      if (variantsBasedOnModelRange.length === 1) {
        logger.debug('[CONFIG IMPORT] Found variant based on model range since there was only one', {
          modelRange,
          model: variantsBasedOnModelRange[0].id,
        });
        return variantsBasedOnModelRange[0];
      }
      const foundBasedOnMatchingSpec = findClosestMatchingModelBasedOnInspiredSpec(
        variantsBasedOnModelRange,
        configurations
      );
      logger.debug('[CONFIG IMPORT] Found variant based on model range and inspired spec matching', {
        modelRange,
        model: foundBasedOnMatchingSpec.id,
      });
      return foundBasedOnMatchingSpec;
    }
  }
  logger.warn('[CONFIG IMPORT] No model could be matched at all for this code and range', {
    modelCode,
    modelRange,
  });
  return null;
};
