import { createActionGroup, props } from '@ngrx/store';
import { Theme } from '@rrvis/ui';

export const GlobalActions = createActionGroup({
  source: 'RR',
  events: {
    'set brand': props<{ brand: string }>(),
    'set theme': props<{ theme: Theme; ctaButtonColor: string; activeSelectionBorderColor: string }>(),
    'set overlayActive': props<{ active: boolean }>(),
    'set configId': props<{ configId: string }>(),
  },
});
