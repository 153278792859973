import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-text-image',
  templateUrl: './text-image.component.html',
  styleUrl: './text-image.component.scss',
  host: { class: 'text-image container' },
  encapsulation: ViewEncapsulation.None,
})
export class TextImageComponent {
  @Input() title?: string;

  @Input() description?: string;

  @Input() reversed?: boolean;

  @Input() image?: string;

  @HostBinding('class.text-image--reversed') get isReversed() {
    return this.reversed;
  }
}
