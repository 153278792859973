import {
  DigitalBrochureEmissionsOverview,
  DigitalBrochureEmissionsSourceType,
  DigitalBrochureEmissionsTable,
  EmissionsSourceType,
} from './interfaces/emissions';

export enum Languages {
  EN = 'en',
  ZH = 'zh',
}

export const languages: readonly string[] = [Languages.EN, Languages.ZH];

export const brands = ['rollsroyce'] as const;

export const isValidLanguage = (language?: string): language is Language => {
  return !!language && languages.includes(language);
};

export const isValidBrand = (brand?: string): brand is Brand => {
  return !!brand && (brands as unknown as string[]).includes(brand);
};
export const isValidCountry = (country?: string | null): country is Country => {
  return !!country && typeof country === 'string';
};

export const isValidEveResponse = (response?: unknown): response is EveResponse => {
  return (
    typeof response === 'object' &&
    Object.prototype.hasOwnProperty.call(response, 'resources') &&
    Object.prototype.hasOwnProperty.call(response, 'configuration')
  );
};

export const isEveError = (response?: unknown): response is EveError => {
  return (
    !isValidEveResponse(response) &&
    typeof response === 'object' &&
    Object.prototype.hasOwnProperty.call(response, 'Code') &&
    Object.prototype.hasOwnProperty.call(response, 'Message')
  );
};

export enum Status {
  OK = 'OK',
  NOT_YET_AVAILABLE = 'NOT_YET_AVAILABLE',
  NOT_FOUND = 'NOT_FOUND',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
}

export type Language = (typeof languages)[number];

export type Brand = (typeof brands)[number];
export type Country = string;

export type FooterData = {
  links: FooterLink[];
  socialNetworks: SocialNetwork[];
};

export type FooterLink = {
  title: string;
  alt: string;
  url: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  internal?: 'true' | 'false';
};

export type SocialNetwork = {
  title: string;
  alt: string;
  url: string;
  icon: string;
};

export type VehicleDataProps = {
  configId: string;
  language: Language;
  brand: Brand;
  country: string | null;
};

export enum HeroVideoLabel {
  BLACK_BADGE = 'BLACK_BADGE',
}

export enum ContentElementType {
  HeroVideo = 'hero-video',
  HeroImage = 'hero-image',
  TextBlock = 'text-block',
  TextImage = 'text-image',
  Teaser = 'teaser',
  Footnotes = 'footnotes',
  CTA = 'cta',
  CTA_CON = 'cta-con',
  CTA_GROUP = 'cta-group',
  Carousel = 'carousel',
  Emissions = 'emissions',
  Accordion = 'accordion',
  Specifications = 'specifications',
  HeadlineBlock = 'headline-block',
}

export type HeroVideoElement = {
  type: ContentElementType.HeroVideo;
  data: {
    title: string;
    subline: string;
    label?: HeroVideoLabel;
    video: string;
    image: string;
    wordmark: string;
  };
};

export type HeroImageElement = {
  type: ContentElementType.HeroImage;
  data: {
    title: string;
    description: string;
    image: string;
  };
};

export type TextBlockElement = {
  type: ContentElementType.TextBlock;
  data: {
    title: string;
    description: string;
  };
};

export type TextImageElement = {
  type: ContentElementType.TextImage;
  data: {
    title: string;
    description: string;
    image: string;
    reversed: boolean;
  };
};

export type TeaserElement = {
  type: ContentElementType.Teaser;
  data: {
    title: string;
    paragraphs: string[];
    image: string;
    link: {
      url: string;
      label: string;
    };
  };
};

export type EmissionDisclaimer = {
  [validFromYear: string]: string[];
};

export type FootnotesAdditionalData = {
  emissionDisclaimer?: Record<EmissionsSourceType, EmissionDisclaimer>;
};

export type FootnotesElement = {
  type: ContentElementType.Footnotes;
  data: {
    paragraphs: string[];
    additionalData?: FootnotesAdditionalData;
  };
};

export const isConfiguratorCTA = (item: CTAItem): item is CTAConElement => item.type === ContentElementType.CTA_CON;

export type CTAItem = CTAElement | CTAConElement;

export type CTAGroupElement = {
  type: ContentElementType.CTA_GROUP;
  data: {
    items: CTAItem[];
  };
};

export type CTAElement = {
  type: ContentElementType.CTA;
  data: {
    url: string;
    label: string;
    theme?: 'primary' | 'secondary' | 'transparent' | 'violet';
  };
};

export type CTAConElement = {
  type: ContentElementType.CTA_CON;
  data: {
    label: string;
    theme?: 'primary' | 'secondary' | 'transparent' | 'violet';
  };
};

export type CarouselElement = {
  type: ContentElementType.Carousel;
  data: {
    shots: {
      image: string;
      caption: string;
    }[];
  };
};

interface EmissionsElementData {
  tables: DigitalBrochureEmissionsTable[];
  image: string;
  source: EmissionsSourceType | DigitalBrochureEmissionsSourceType;
}

export type EmissionsElement = {
  type: ContentElementType.Emissions;
  data: EmissionsElementData;
};

export type AccordionElementItem = {
  title: string;
  description: string;
  child: ContentElement;
};

export const isEmissionsAccordion = (
  item: AccordionElementItem | AccordionEmissionsItem
): item is AccordionEmissionsItem => typeof item.description !== 'string';
export const isEmissionsChildData = (item: ContentElement): item is EmissionsElement =>
  Object.prototype.hasOwnProperty.call(item.data, 'tables');

export interface AccordionEmissionsItem {
  title: string;
  description: DigitalBrochureEmissionsOverview;
  child: ContentElement;
}

export type AccordionItem = AccordionElementItem | AccordionEmissionsItem;

export type AccordionElement = {
  type: ContentElementType.Accordion;
  data: {
    items: AccordionItem[];
  };
};

export type SpecificationsElement = {
  type: ContentElementType.Specifications;
  data: {
    specs: any[];
  };
};

export type HeadlineBlockElement = {
  type: ContentElementType.HeadlineBlock;
  data: {
    title: string;
  };
};

export type DefaultContentElementData = {
  image?: string;
  video?: string;
  shots?: {
    image: string;
    caption: string;
  }[];
};

/** Parsed and transformed content element */
export type ContentElement = (
  | HeroVideoElement
  | HeroImageElement
  | TextBlockElement
  | TextImageElement
  | TeaserElement
  | FootnotesElement
  | CTAElement
  | CTAConElement
  | CTAGroupElement
  | CarouselElement
  | EmissionsElement
  | AccordionElement
  | SpecificationsElement
  | HeadlineBlockElement
) & {
  data: DefaultContentElementData;
};

/** Raw content element coming from editors */
export type RawContentElement = Omit<ContentElement, 'data'> & {
  data: {
    cameraPosition?: string;
    videoPosition?: string;
    shots?: {
      cameraPosition: string;
      caption: string;
    }[];
  };
};

export type VehicleData = {
  meta: {
    blackBadge: boolean;
    ctaButtonColor: string;
    activeSelectionBorderColor: string;
    configId: string;
    language: Language;
    brand: Brand;
  };
  content: ContentElement[];
};

export type VehicleDataResult = {
  status: Status;
  data?: VehicleData;
};

export type EveError = {
  Code: 'NotFoundError';
  Message: string;
};

export type EveResponse = {
  trunk: string;
  resources: {
    images: string[];
    videos: string[];
  };
  validity: string;
  urlDigitalBrochure: string;
  urlConfigurator: string;
  configuration: {
    agModelCode: string;
    brand: 'RR' | 'BMW';
    creationDate?: string;
    effectDate: string;
    fabricDefault: string;
    featureCodes: string[];
    language: string;
    market: string;
    modelCode: string;
    modelRange: string;
    paintDefault: string;
    resetVehicle: string;
    rightHandDrive: string;
    series: string;
    wheelCodeDefault: string;
    isBlackBadge: boolean;
  };
  configTrunkLastModified: string;
};

export type EveMedia = {
  [key: string]: string;
};

export type EveData = {
  isBlackBadge: boolean;
  agModelCode: string;
  modelRange: string;
  featureCodes: string[];
  images: EveMedia;
  videos: EveMedia;
  effectDate: string;
  market: string;
};

export type ConditionalStructureElementOption = {
  condition: string[][];
  bricks: string[];
};

export type ConditionalStructureElement = ConditionalStructureElementOption[];

export type StructureElement = string | ConditionalStructureElement;

export const isConditionalStructureElement = (input?: unknown): input is ConditionalStructureElement => {
  return (
    Array.isArray(input) &&
    input.every(
      (item) =>
        Object.prototype.hasOwnProperty.call(item, 'condition') && Object.prototype.hasOwnProperty.call(item, 'bricks')
    )
  );
};

export type ContentFile = {
  structure: StructureElement[];
  bricks: {
    [key: string]: RawContentElement;
  };
};

export type TranslationFile = {
  [key: string]: string;
};

export type Feature = {
  code: string;
  groupCode: string;
  packageContent: string[];
  sortingOrder: null | number;
  sortingOrderInPackage: null | {
    [key: string]: number;
  };
};

export type ResolvedFeature = {
  code: string;
  packageContent: ResolvedFeature[];
  sortingOrder: null | number;
  sortingOrderInPackage: null | {
    [key: string]: number;
  };
};

export type FeaturesFile = Feature[];

export type LookupFile = {
  [key: string]: string[];
};

export type TreeFile = {
  [key: string]: {
    [key: string]: {
      [key: string]: {
        content: (string | ResolvedFeature)[];
      };
    };
  };
};

export type VDMLFile = {
  zh: TranslationFile;
  en: TranslationFile;
  features: FeaturesFile;
  lookup: LookupFile;
  tree: TreeFile;
};

export interface Group {
  id: string;
  label?: string;
  selectionLogic?: string;
  hotspotIDs: string[];
  minusCodes: string[];
  minusGroupIDs: string[];
  subgroups: Subgroup[];
  isHidden?: boolean;
  restrictedTo?: string[];
}

export interface Subgroup {
  id: string;
  label?: string;
  swipePosition: number;
  bundles: Bundle[];
}

export interface Bundle {
  id: string;
  label: string;
  featureCodes: string[];
  minusCodes: string[];
  minusGroupIDs: string[];
  isHidden?: boolean;
  isCheckbox?: boolean;
  restrictedTo?: string[];
  splitterName?: string[];
  splitterN?: string;
  defaultHEXValue?: string;
}

export type UIFile = {
  groups: Group[];
};

export interface Link {
  title: string;
  alt: string;
  url: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  internal?: 'true' | 'false';
}

export type FooterFile = FooterData;

export type SplitCode = {
  featureCode: string;
  colorCode: string;
};

export type Specification = {
  code: string;
  colorCode: string;
  colorName: string;
  colorHexCode: string;
  name: string;
  packageContent: Specification[];
};

export type SalesGroup = {
  name: string;
  sortingOrder: number;
  content: (SalesGroup | Specification)[];
};

export type Specifications = SalesGroup[];

export type EveResolverError =
  | 'NotFoundError'
  | 'MissingContentError'
  | 'MissingSpecificationsError'
  | 'UnknownError'
  | 'ExpiredError'
  | unknown;

export interface ExternalVehicleDataGroup {
  readonly id: string;
  readonly label: string;
  readonly selectionLogic: 'non-deselectable' | 'deselectable';
  readonly hotspotIDs: string[];
  readonly minusCodes: string[];
  readonly minusGroupIDs: string[];
  readonly subgroups: ExternalVehicleDataSubgroup[];
}
export interface ExternalVehicleDataSubgroup {
  readonly id: string;
  readonly label: string;
  readonly swipePosition: number;
  readonly bundles: ExternalVehicleDataBundle[];
}
export interface ExternalVehicleDataBundle {
  readonly label: string;
  readonly featureCodes: string[];
  readonly minusCodes: string[];
  readonly minusGroupIDs: string[];
  readonly isHidden?: boolean;
  readonly splitterName: string[];
  readonly splitterN: string;
  readonly agModelCode: string;
  readonly defaultHEXValue: string;
  readonly RHD: boolean;
}
export interface ExternalVehicleData {
  readonly groups: ExternalVehicleDataGroup[];
}

export type ConfigurationCodeToVisualiserCodeResult = {
  visualiserCodes: string[];
  bundleIds: string[];
  finalBundles: Bundle[];
  notFoundFeatures: string[];
  genericColourFeatures: Record<string, string>;
};
