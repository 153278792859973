<div class="teaser__visual">
  <img class="teaser__img" [src]="image.includes('http') ? image : '/public' + image" [alt]="title" />
</div>
<div class="teaser__content">
  <h2 class="teaser__title">{{ title }}</h2>
  <p *ngFor="let p of paragraphs" class="teaser__paragraph">{{ p }}</p>
  <a [href]="link?.url" class="teaser__link">
    {{ link?.label }}
    <ui-icon icon="rightArrow" class="teaser__link-icon" [theme]="theme" />
  </a>
</div>
