<main
  id="main"
  class="app"
  [ngClass]="{ 'theme--black-badge': (theme$ | async) === 'dark' }"
  [style.--bb-button-color]="ctaButtonColor"
  [style.--bb-active-selection-root-color]="activeSelectionBorderColor"
>
  <ui-cursor></ui-cursor>
  <router-outlet></router-outlet>
  <app-footer
    [links]="footerLinks"
    [socialNetworks]="footerSocialLinks"
    [language]="nextLanguage"
    [weChatQrCode]="getWeChatQrCode()"
    (languageClicked)="toggleLanguage()"
    [logoHref]="logoHref$ | async"
  ></app-footer>
</main>
