import { SpecificationMappingService, Variant } from '@rrvis/vehicle';
import { Specifications, Language } from './types';
import { logger } from './logger';
import { getTranslationsFile, readVDMLFile } from './helper/feature';

export const ADDITIONAL_PRIMARY_GROUP = 'prim_add';
export const ADDITIONAL_SECONDARY_GROUP = 'sec_add';
export const ADDITIONAL_TERTIARY_GROUP = 'tert_add';

type GetSpecificationsParameters = {
  modelVariant: Variant;
  modelRange: string;
  agModelCode: string;
  featureCodes: string[];
  isBlackBadge: boolean;
  language: Language;
};

export const hasI18nTranslation = (featureCode: string, translations: Record<string, string>) => {
  if (!translations) {
    return false;
  }
  return translations[`f_${featureCode}`];
};

export const getSpecifications = async ({
  modelVariant,
  agModelCode,
  featureCodes,
  isBlackBadge,
  language,
}: GetSpecificationsParameters): Promise<Specifications> => {
  try {
    const [vdml, i18n] = await Promise.all([
      await readVDMLFile(agModelCode, isBlackBadge),
      await getTranslationsFile(language),
    ]);
    //@ts-expect-error wrong typings
    const specMapper = new SpecificationMappingService(logger);
    const model = {
      idTranslation: `${modelVariant.id}.${agModelCode}`,
      modelFakeContent: {
        selected: true,
        code: agModelCode,
        label: `${modelVariant.id}.${agModelCode}`,
        sortingOrder: 0,
      },
    };
    const specs = await specMapper.getSpecifications(model, featureCodes, vdml, i18n, language);
    return specs;
  } catch (e) {
    logger.error('Failed to get specifications', { e });
    throw new Error('MissingSpecificationsError');
  }
};
