import { DateTime } from 'luxon';

export type EmissionsRange = { best: number; worst: number };
export type EmissionValue = {
  imperial: number | null;
  metric: number;
  range?: { imperial: EmissionsRange | null; metric: EmissionsRange };
};

export type EmissionsData = {
  [key: string]: ResponseEntry;
};
export type EmissionsUnits = { imperial?: string; metric: string };

export type ResponseEntry = {
  units?: EmissionsUnits;
  value?: EmissionValue;
  values?: EmissionLevels;
};

export enum EmissionLevel {
  COMB = 'comb',
  LOW = 'low',
  MID = 'mid',
  HIGH = 'high',
  EX_HIGH = 'exHigh',
  URBAN = 'city',
  EX_URBAN = 'exCity',
}

export interface EmissionLevels {
  [EmissionLevel.COMB]?: EmissionValue;
  [EmissionLevel.LOW]?: EmissionValue;
  [EmissionLevel.MID]?: EmissionValue;
  [EmissionLevel.HIGH]?: EmissionValue;
  [EmissionLevel.EX_HIGH]?: EmissionValue;
  [EmissionLevel.URBAN]?: EmissionValue;
  [EmissionLevel.EX_URBAN]?: EmissionValue;
}

export enum EmissionsSourceType {
  BINDING = 'binding',
  NON_BINDING = 'non-binding',
  STATIC = 'static',
}

export interface LayoutEmissionsRange {
  best: string;
  worst: string;
}

export interface LayoutResponseEntry {
  translationKey: string;
  data: ResponseEntry;
}

export interface LayoutResponseTableRow {
  translationKey: string;
  data: {
    value?: string;
    range?: LayoutEmissionsRange;
  };
}

export interface LayoutResponseTable {
  headers: string[];
  rows: LayoutResponseTableRow[][];
}

export interface PdfSalesGroupDisclaimers {
  [key: string]: string;
}

export interface EmissionsLayoutResponse {
  overview: {
    wltp: LayoutResponseEntry[];
    nedc: LayoutResponseEntry[];
    noise: LayoutResponseEntry[];
  };
  short: LayoutResponseEntry;
  tables: LayoutResponseTable[];
  disclaimers: string[];
  usedComputationDate: string;
  legislator: string;
  pdfSalesGroupDisclaimers: PdfSalesGroupDisclaimers;
  source: EmissionsSourceType;
}

type DigitalBrochureEmissionsTableHeaders = string[];
type DigitalBrochureEmissionsRange = {
  best: string;
  worst: string;
};
type DigitalBrochureEmissionsTableRow = {
  translationKey: string;
  data: {
    value?: string;
    range?: DigitalBrochureEmissionsRange;
  };
};

export type DigitalBrochureEmissionsTable = {
  headers: DigitalBrochureEmissionsTableHeaders;
  rows: DigitalBrochureEmissionsTableRow[][];
};

export enum DigitalBrochureEmissionsSourceType {
  FAILED = 'failed',
}

export interface DigitalBrochureLayoutResponseEntry {
  translationKey: string;
  data: ResponseEntry;
}

export interface DigitalBrochureEmissionsOverview {
  wltp?: DigitalBrochureLayoutResponseEntry[];
  nedc?: DigitalBrochureLayoutResponseEntry[];
  noise?: DigitalBrochureLayoutResponseEntry[];
}

export interface DigitalBrochureEmissionsData {
  overview?: DigitalBrochureEmissionsOverview;
  short?: LayoutResponseEntry;
  tables?: DigitalBrochureEmissionsTable[];
  disclaimers?: string[];
  usedComputationDate?: string;
  validFrom?: DateTime;
  legislator?: string;
  pdfSalesGroupDisclaimers?: PdfSalesGroupDisclaimers;
  source: EmissionsSourceType | DigitalBrochureEmissionsSourceType;
}

export interface EmissionsServiceApiPayload {
  /**
   * The model code which is different between LHD vs RHD
   *
   * @example TK21
   */
  modelCode: string;
  /**
   * The model id or range of the car
   *
   * @example RR21
   */
  modelId: string;
  /**
   * The derivative name of the model
   *
   * @example Spectre
   */
  derivativeId: string;
  binding: boolean;
  configuration: string[];
  productionDate: string;
  countryCode?: string;
}
