import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-hero-image',
  templateUrl: './hero-image.component.html',
  styleUrl: './hero-image.component.scss',
  host: { class: 'hero-image' },
  encapsulation: ViewEncapsulation.None,
})
export class HeroImageComponent {
  @Input() image?: string;

  @Input() title?: string;

  @Input() description?: string;
}
