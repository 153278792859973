import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CTAElement } from '@digital-brochure/services';
import { Theme } from '@rrvis/ui';

@Component({
  selector: 'app-cta-group',
  templateUrl: './cta-group.component.html',
  styleUrl: './cta-group.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class CTAGroupComponent {
  @Input() iconTheme: Theme;
  @Input() items: CTAElement[];
}
