/* eslint-disable no-console */
import merge from 'lodash.merge';

const logMessage = (level: string, message: string, ...args: object[]): void => {
  // Quick Fix for failling deployment tests
  if (!args?.reduce) {
    throw new Error(`Log level ${level} not supported!`);
  }

  const logObject = JSON.stringify(
    args.reduce(merge, {
      level,
      message: typeof message === 'string' ? message.trim() : message,
      timestamp: new Date().toISOString(),
    })
  );
  switch (level) {
    case 'DEBUG':
      console.debug(logObject);
      break;
    case 'INFO':
      console.info(logObject);
      break;
    case 'WARN':
      console.warn(logObject);
      break;
    case 'ERROR':
      console.error(logObject);
      break;
    default:
      throw new Error(`Log level ${level} not supported!`);
  }
};

const debug = (message: string, ...args: object[]): void => logMessage('DEBUG', message, ...args);

const info = (message: string, ...args: object[]): void => logMessage('INFO', message, ...args);

const warn = (message: string, ...args: object[]): void => logMessage('WARN', message, ...args);

const error = (message: string, ...args: object[]): void => logMessage('ERROR', message, ...args);

export const logger = {
  debug,
  info,
  warn,
  error,
};
