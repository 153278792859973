import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-text-block',
  templateUrl: './text-block.component.html',
  styleUrl: './text-block.component.scss',
  host: { class: 'text-block' },
  encapsulation: ViewEncapsulation.None,
})
export class TextBlockComponent {
  @Input() title?: string;

  @Input() description?: string;
}
