import { Translations } from '@rrvis/vehicle';
import { logger } from '../logger';
import {
  Feature,
  FeaturesFile,
  FooterFile,
  Language,
  Languages,
  ResolvedFeature,
  SplitCode,
  TranslationFile,
  UIFile,
  VDMLFile,
} from '../types';
import { getColorCodePath, getFooterFilePath, getLanguageFile, getUIFilePath, getVdmlFilePath } from './file-paths';
import { readContentFilesFromS3 } from './s3';

export const readVDMLFile = async (agModelCode: string, isBlackBadge: boolean): Promise<VDMLFile> => {
  // XZ.. is a special model code that has no own Black Badge model code
  const path = getVdmlFilePath(agModelCode, isBlackBadge);
  const file = await readContentFilesFromS3<VDMLFile>(path);

  if (!file) {
    logger.error(`VDML file does not exist.`, {
      path,
      isBlackBadge,
      agModelCode,
    });
    throw new Error(`VDML file ${path} does not exist.`);
  }

  return file;
};

export const readUIFile = async (derivative: string, modelRange: string): Promise<UIFile> => {
  // XZ.. is a special model code that has no own Black Badge model code
  const path = getUIFilePath(derivative, modelRange);
  const file = await readContentFilesFromS3<UIFile>(path);

  if (!file) {
    logger.error(`UI file does not exist.`, {
      path,
    });
    throw new Error(`UI file ${path} does not exist.`);
  }

  return file;
};

export const readFooterFile = async (lang: Language): Promise<FooterFile> => {
  // XZ.. is a special model code that has no own Black Badge model code
  const path = getFooterFilePath(lang);
  const file = await readContentFilesFromS3<FooterFile>(path);

  if (!file) {
    logger.error(`UI file does not exist.`, {
      path,
    });
    throw new Error(`UI file ${path} does not exist.`);
  }

  return file;
};

export const readColorFile = async (): Promise<TranslationFile> => {
  const path = getColorCodePath();
  const file = await readContentFilesFromS3<TranslationFile>(path);

  if (!file) {
    logger.error('Color file does not exist', { path });
    throw new Error(`Color file could not be found.`);
  }

  return file;
};

export const readLanguageFile = async (lang: Language): Promise<TranslationFile> => {
  const path = getLanguageFile(lang);
  const file = await readContentFilesFromS3<TranslationFile>(path);

  if (!file) {
    logger.error(`${lang}.json does not exist.`, {
      path,
      lang,
    });
    throw new Error(`Language file ${path} does not exist.`);
  }

  return file;
};

export const getTranslationsFile = async (lang: Language): Promise<Translations> => {
  const en = await readLanguageFile(Languages.EN);
  const zh = lang === Languages.ZH ? await readLanguageFile(Languages.ZH) : {};
  return {
    en: { ...en },
    zh: { ...zh },
  };
};

export const splitCode = (code: string): SplitCode => {
  const splitCodes = code.split('_');
  const featureCode = splitCodes.shift();
  const colorCode = splitCodes.join('_');

  return { featureCode: featureCode || '', colorCode };
};

export const resolveColorHexCode = (colorCode: string, colors: TranslationFile): string => {
  let colorHexCode = colors[colorCode];

  if (colorCode.length === 3) {
    colorHexCode = colors[`W${colorCode}`] || colors[`Y${colorCode}`];
  }

  return colorHexCode;
};

export const isVisibleFeature = (feature: ResolvedFeature): boolean => !!feature.code;

export const isContainedFeature =
  (featureCodes: string[]) =>
  // eslint-disable-next-line indent
  (feature: ResolvedFeature): boolean =>
    // eslint-disable-next-line indent
    featureCodes.includes(feature.code) || featureCodes.some((item) => item.startsWith(`${feature.code}_`));

export const hasTranslation = (translation: TranslationFile, key: string) => {
  const value = translation[key];
  return value !== undefined;
};

export const findFeature = (translation: TranslationFile, code: string, features: Feature[]): Feature => {
  const featureInTree = features.find((f) => f.code === code || f.code === `f_${code}`) as Feature;
  if (!featureInTree) {
    const featureInI18n = hasTranslation(translation, `f_${code}`);
    if (featureInI18n) {
      return {
        code,
        groupCode: '',
        packageContent: [],
        sortingOrder: 1234567890,
        sortingOrderInPackage: {},
      };
    }
  }
  return featureInTree;
};

export const resolveFeature = (
  translation: TranslationFile,
  code: string,
  features: FeaturesFile,
  featureCodes: string[]
): ResolvedFeature => {
  const feature = findFeature(translation, code, features);

  const packageContent: ResolvedFeature[] =
    feature?.packageContent
      .map((f) => resolveFeature(translation, f, features, featureCodes))
      .filter(isVisibleFeature)
      .filter(isContainedFeature(featureCodes)) || [];

  return {
    code: feature?.code || '',
    sortingOrder: feature?.sortingOrder,
    sortingOrderInPackage: feature?.sortingOrderInPackage || null,
    packageContent,
  };
};
