<div #wrapper class="hero-video" [ngClass]="playing ? 'hero-video--playing' : ''">
  <div class="hero-video__caption" (click)="playVideo($event, false)">
    <h1 class="hero-video__title">
      <span *ngIf="!wordmark && label" class="hero-video__title-label">
        <img *ngIf="label === 'BLACK_BADGE'" class="hero-video__title-wordmark" src="/public/black-badge.svg" />
      </span>
      <span *ngIf="wordmark && !label" class="hero-video__title-label">
        <img *ngIf="wordmark" class="hero-video__title-wordmarkalternative"  [src]="wordmark.includes('http') ? wordmark : '/public' + wordmark" [alt]="" />
      </span>
      <span *ngIf="!wordmark" class="hero-video__title-top">{{ title }}</span>
      <span *ngIf="subline" class="hero-video__title-subline">{{ subline }}</span>
    </h1>
    <button *ngIf="videoUrl && canPlay" type="button" class="hero-video__play-btn" (click)="playVideo($event, true)">
      <ng-container>
        <app-play-svg class="hero-video__play-icon"></app-play-svg>
        {{ startVideoLabel }}
      </ng-container>
    </button>
  </div>
  <video #video *ngIf="videoUrl" class="hero-video__video" loop playsInline [poster]="posterUrl" preload="metadata">
    <ng-container>
      <source [src]="videoUrl" type="video/mp4" />
      {{ videoFallback }}
    </ng-container>
  </video>
  <img *ngIf="!videoUrl" [src]="posterUrl" class="hero-video__poster" alt="" />
</div>
