import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-foot-notes',
  templateUrl: './foot-notes.component.html',
  styleUrl: './foot-notes.component.scss',
  host: { class: 'footnotes' },
  encapsulation: ViewEncapsulation.None,
})
export class FootNotesComponent {
  @Input() paragraphs?: string[];
}
