import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-scroll-indicator',
  templateUrl: './scroll-indicator.component.html',
  styleUrl: './scroll-indicator.component.scss',
  host: { class: 'scroll-indicator' },
  encapsulation: ViewEncapsulation.None,
})
export class ScrollIndicatorComponent {}
