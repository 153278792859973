import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromFetch } from 'rxjs/fetch';
import { catchError, Observable, of, switchMap, timeout } from 'rxjs';
import { environment } from '../../environments/environment';
import { STORAGE_ENTRIES, StorageManagerService } from './storage-manager.service';

export enum Language {
  EN = 'en',
  ZH = 'zh',
}

export interface CountryConfigResponse {
  defaultLang?: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageManagerService {
  SUPPORTED_LANGS = [Language.EN, Language.ZH];

  constructor(
    private readonly translate: TranslateService,
    private readonly storageManager: StorageManagerService
  ) {
    this.translate.addLangs(this.SUPPORTED_LANGS);
  }

  setLanguage(lang: string) {
    this.translate.use(lang);
    this.storageManager.saveToStorage(STORAGE_ENTRIES.LANG, lang);
  }

  availableLanguages() {
    return this.SUPPORTED_LANGS;
  }

  getUserSavedLanguage() {
    return this.storageManager.getFromStorage<Language>(STORAGE_ENTRIES.LANG);
  }

  getUserLanguageFromConfig(): Observable<CountryConfigResponse> {
    const url = environment.countryConfigPath;
    return fromFetch(url).pipe(
      timeout(5000),
      switchMap(response => {
        if (response.ok)
          return response.json() as Promise<CountryConfigResponse>;
        else return of({ defaultLang: Language.EN });
      }),
      catchError(() => {
        return of({ defaultLang: Language.EN });
      })
    );
  }
}
