import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-error-block',
  templateUrl: './error-block.component.html',
  styleUrl: './error-block.component.scss',
  host: { class: 'error-block' },
})
export class ErrorBlockComponent {
  @Input() title: string = '';

  @Input() headline: string = '';

  @Input() description: string = '';
}
