import { createReducer, on } from '@ngrx/store';
import { initialState } from './initial';
import { AppState } from './state.types';
import { TranslationActions } from './actions/translation';
import { GlobalActions } from './actions/global';

const _appReducer = createReducer(
  initialState,
  on(
    TranslationActions.setLang,
    (state, payload): AppState => ({
      ...state,
      lang: payload.lang,
    }),
  ),
  on(
    GlobalActions.setBrand,
    (state, payload): AppState => ({
      ...state,
      brand: payload.brand,
    }),
  ),
  on(
    GlobalActions.setTheme,
    (state, payload): AppState => ({
      ...state,
      theme: payload.theme,
      ctaButtonColor: payload.ctaButtonColor || state.ctaButtonColor,
      activeSelectionBorderColor: payload.activeSelectionBorderColor || state.activeSelectionBorderColor,
    }),
  ),
  on(
    GlobalActions.setOverlayActive,
    (state, payload): AppState => ({
      ...state,
      overlayActive: payload.active,
    }),
  ),
  on(
    GlobalActions.setConfigId,
    (state, payload): AppState => ({
      ...state,
      configId: payload.configId,
    }),
  ),
);

export const appReducer = (state, action): AppState => _appReducer(state, action);
